import React, { ReactNode, useEffect, useRef } from "react";
import "./styles.scss";

interface SlidDownProps {
  isOpen: boolean;
  children: ReactNode;
  classNameChildren?: string;
  title?: string;
  classNameContainer?: string;
  closeArea?: () => void;
}

const SlidDown: React.FC<SlidDownProps> = ({
  children,
  isOpen,
  classNameChildren,
  title,
  classNameContainer,
  closeArea,
}) => {
  const slidDownRef = useRef<HTMLDivElement>(null);

  const closeSlidDown = () => {
    const slidDown = slidDownRef.current;
    if (slidDown) {
      slidDown.classList.remove("open");
      slidDown.classList.add("close");
      setTimeout(() => {
        if (slidDown.classList.contains("close")) {
          slidDown.style.display = "none";
        }
      }, 350);
    }
  };

  const openSlidDown = () => {
    const slidDown = slidDownRef.current;
    if (slidDown) {
      slidDown.style.display = "block";
      requestAnimationFrame(() => {
        slidDown.classList.add("open");
        slidDown.classList.remove("close");
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      openSlidDown();
    } else {
      closeSlidDown();
    }
  }, [isOpen]);

  return (
    <div
      className={classNameContainer ?? ""}
      onClick={() => {
        if (closeArea) closeArea();
      }}
      id="slid-down-container"
      ref={slidDownRef}
    >
      <div className="slid-down-content" onClick={(e) => e.stopPropagation()}>
        {title && <h4 className="slid-down-title">{title}</h4>}
        <div className={classNameChildren}>{children}</div>
      </div>
    </div>
  );
};

export default SlidDown;
