import { useEffect, useState } from "react";
import "./styles.scss";
import PageTitle from "components/PageTitles";
import { useDeskNavigation } from "contexts/useDeskNavigation";
import { IToast } from "types/toast";
import Toast from "components/Toast";
import { useModal } from "contexts/useModal";
import Button from "components/Buttons/Button";
import ModalFilterFlights from "components/Modal/ModalFilterFlights";
import { useUserInfo } from "contexts/userInfo";
import { IOptionInputTextSelectProps } from "components/Inputs/InputTextSelect";
import postUserAlerts, {
  PostUserAlertsBodyType,
} from "api/alerts/postUserAlerts";
import SlidUpFilterFlights from "components/Modal/SlidUpFilterFlights";
import getAirportsCodes from "api/flights/getAirportsCodes";
import { IAirportsSearch } from "types/airportsSearch";
import InputText from "components/Inputs/InputText";
import CloseIconPeach from "../../assets/icons/close/close-icon-peach.svg";
import BellOrange from "../../assets/icons/bells/bell-orange.svg";

import SubText from "components/Texts/SubText";
import TextActionCard from "components/Cards/TextActionCard";
import { useWindowWidthSize } from "contexts/useWindowWidthSize";
import { FilterFlightObj } from "types/filter";

const UserSettingsAlertsPage: React.FC = () => {
  const { userInfo } = useUserInfo();
  const { setModalContent, setOpenUseModal } = useModal();
  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });
  const [isSlideUpVisible, setIsSlidUpVisible] = useState(false);
  const [isUserFit, setUserFit] = useState(false);

  const { setPrincipalScreen, setSecondaryScreen } = useDeskNavigation();
  const { windowSize } = useWindowWidthSize();

  const [inputSearchResultOptions, setInputSearchResultOptions] = useState(
    {} as IOptionInputTextSelectProps[]
  );

  useEffect(() => {
    if (
      userInfo["@air-fly_user-plan"] === "global" ||
      userInfo["@air-fly_user-plan"] === "travel"
    ) {
      setUserFit(true);
    }
  }, []);

  const addPhoneNumberPage = () => {
    if (isUserFit) {
      setPrincipalScreen("/add-phone-number");
    } else {
      setModalContent(
        <section className="payment-response">
          <span className="icon icon-airplane icon-md icon-orange "></span>
          <h5 className="no-margin margin-top-16">
            <b>Oops, you need to upgrade your plan!</b>
          </h5>
          <p className="small-p margin-bottom-16">
            Unlock all filters with our premium plans!
          </p>
          <div className="buttons-container">
            <Button
              children={"Upgrade Now"}
              buttonStyle={"primary"}
              onClick={() => {
                handleTryAgain();
              }}
            />
            <Button
              children={"Continue Free"}
              buttonStyle={"tertiary"}
              onClick={() => {
                setOpenUseModal(false);
              }}
            />
          </div>
        </section>
      );
      setOpenUseModal(true);
    }
  };

  const searchFilteredConsultCallback = async (data: FilterFlightObj) => {
    try {
      // y: "economic",
      // w: "premium",
      // j: "executive",
      // f: "first",
      const classSymbol: "Y" | "W" | "J" | "F" | undefined =
        data.classSelected === "economic"
          ? "Y"
          : data.classSelected === "premium"
          ? "W"
          : data.classSelected === "executive"
          ? "J"
          : data.classSelected === "first"
          ? "F"
          : undefined;

      const prepareParams: PostUserAlertsBodyType = {
        classes: classSymbol ? [classSymbol] : undefined,
        date: data.dateSelected,
        destination: data.destinationIATA,
        origin: data.departureIATA,
        direct:
          data.stopsSelected === "Direct"
            ? true
            : data.stopsSelected === "Stopover"
            ? false
            : undefined,
      };

      const request = await postUserAlerts(
        userInfo["@air-fly_access-token"],
        prepareParams
      );
      switch (request.status) {
        case 201:
          // setShouldReload(prev => prev +1)
          setToastConfig({
            description: "Your alert has been successfully created",
            title: "Alert Added",
            type: "success",
          });

          setToastShow(true);
          setTimeout(() => {
            setToastShow(false);
          }, 4000);
          break;
        case 400:
          if (userInfo["@air-fly_user-plan"] === "global") {
            setToastConfig({
              description: "The limit of possible alerts is three.",
              title: "Alert not added",
              type: "info",
            });
            setToastShow(true);
            setTimeout(() => {
              setToastShow(false);
            }, 4000);
          } else {
            setModalContent(
              <section className="payment-response">
                <span className="icon icon-airplane icon-md icon-orange "></span>
                <h5 className="no-margin margin-top-16">
                  <b>Oops, you need to upgrade your plan!</b>
                </h5>
                <p className="small-p margin-bottom-16">
                  Unlock all filters with our premium plans!
                </p>
                <div className="buttons-container">
                  <Button
                    children={"Upgrade Now"}
                    buttonStyle={"primary"}
                    onClick={() => {
                      handleTryAgain();
                    }}
                  />
                  <Button
                    children={"Continue Free"}
                    buttonStyle={"tertiary"}
                    onClick={() => {
                      setOpenUseModal(false);
                    }}
                  />
                </div>
              </section>
            );
            setOpenUseModal(true);
          }
          break;
        default:
          setToastConfig({
            description:
              "We had a problem making the request. Please try again",
            title: "Sorry",
            type: "error",
          });

          setToastShow(true);
          setTimeout(() => {
            setToastShow(false);
          }, 4000);
          break;
      }
    } catch (error) {
      console.error(error);

      setToastConfig({
        description: "We had a problem making the request. Please try again",
        title: "Sorry",
        type: "error",
      });

      setToastShow(true);
      setTimeout(() => {
        setToastShow(false);
      }, 4000);
    }
  };

  const handleTryAgain = () => {
    setOpenUseModal(false);
    setPrincipalScreen("/user-settings-subscriptions");
  };

  const createAlert = () => {
    if (userInfo["@air-fly_user-plan"] === "global") {
      if (windowSize <= 768) {
        setIsSlidUpVisible(!isSlideUpVisible);
      } else {
        setModalContent(
          <ModalFilterFlights
            inputSearchResultOptions={inputSearchResultOptions}
            titleHead="Create Alert"
            titleText="Destination"
            primaryButtonText="Create Alert"
            filterResultsCallback={searchFilteredConsultCallback}
            showDate
          />
        );
        setTimeout(() => {
          setOpenUseModal(true);
        }, 0);
      }
    } else {
      setModalContent(
        <section className="payment-response">
          <span className="icon icon-airplane icon-md icon-orange "></span>
          <h5 className="no-margin margin-top-16">
            <b>Oops, you need to upgrade your plan!</b>
          </h5>
          <p className="small-p margin-bottom-16">
            Unlock all features with our premium plans!
          </p>
          {/* <p className="small-p margin-bottom-16">
							Unlock all filters with the AirFly Ultimate Plan! Use the promo
							code: "AMIGOS" for 30 days trial.
						</p> */}
          <div className="buttons-container">
            <Button
              children={"Upgrade Now"}
              buttonStyle={"primary"}
              onClick={() => {
                handleTryAgain();
              }}
            />
            <Button
              children={"Continue Free"}
              buttonStyle={"tertiary"}
              onClick={() => {
                setOpenUseModal(false);
              }}
            />
          </div>
        </section>
      );
      setOpenUseModal(true);
    }
  };
  const handleInputChange = async (search: string, category?: string) => {
    try {
      const request = await getAirportsCodes(
        userInfo["@air-fly_access-token"],
        {
          search,
        }
      );

      setInputSearchResultOptions(
        request.res.data.map((airport: IAirportsSearch) => {
          return {
            label: `${airport.iata} - ${airport.name}`,
            detailsText: `${airport.city}, ${airport.country}`,
            value: airport.iata,
          } as IOptionInputTextSelectProps;
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const connectAnotherNumberHandler = () => {
    setPrincipalScreen("/add-phone-number");
  };

  return (
    <div className="user-settings-alerts">
      <PageTitle
        title={"Alerts Settings"}
        buttonAlert
        buttonToAdd={isUserFit}
        addButtonAction={createAlert}
        rightButtonChalk
        buttonAlertAction={() => {
          setPrincipalScreen("/my-alerts");
          setSecondaryScreen("");
        }}
        subtitle={"Manage Notification"}
        onBackClick={() => {
          setPrincipalScreen("/user-settings");
          setSecondaryScreen("");
        }}
        hasCloseIcon={false}
        className="padding-hor-24"
      />
      <main className="user-settings-account__main padding-hor-24">
        {isUserFit ? (
          <div className="main__verified-number">
            <h2 className="verified-number__title">Enable SMS Alerts</h2>
            <p className="verified-number__text">
              Enter your phone number to receive real-time flight updates
              directly to your phone. Stay updated and never miss an alert.
            </p>
            <div className="verified-number__input-button">
              <InputText
                inputType="tel"
                iconRight={CloseIconPeach}
                readOnly
                onClickIconRight={connectAnotherNumberHandler}
                value={"+" + userInfo["@air-fly_user_phone"]}
              />
              <Button
                buttonStyle="secondary"
                onClick={connectAnotherNumberHandler}
              >
                Connect Another Number
              </Button>
              <SubText leftText="*" className="padding-hor-16">
                Connecting a new phone number will replace the existing phone
                number for your alerts. This cannot be undone.
              </SubText>
            </div>
          </div>
        ) : (
          <TextActionCard
            headerIcon={BellOrange}
            buttons={[
              {
                buttonStyle: "primary",
                buttonAction: () => addPhoneNumberPage(),
                text: "Enable SMS Alerts",
              },
            ]}
            principalText="Enable SMS Alerts"
            secondaryText={`Enter your phone number to receive real-time flight 
						updates directly to your phone. Stay updated and never miss an 
						alert.`}
          />
        )}
      </main>
      <SlidUpFilterFlights
        closeSlidUpAreaCallback={setIsSlidUpVisible}
        isOpen={isSlideUpVisible}
        onChangeInput={handleInputChange}
        filterResultsCallback={searchFilteredConsultCallback}
        titleHead="Create Alert"
        titleText="Destination"
        primaryButtonText="Create Alert"
        showDate
      />
      <Toast
        type={toastConfig.type}
        isVisible={toastShow}
        setIsVisible={setToastShow}
        title={toastConfig.title}
        description={toastConfig.description}
      />
    </div>
  );
};

export default UserSettingsAlertsPage;
