import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import getFlightDetailsById from "api/flights/getFlightDetailsById";
import { useUserInfo } from "contexts/userInfo";
import OrderReview from "components/Cards/OrderReview";
import { useDeskNavigation } from "contexts/useDeskNavigation";
import { ClassesFlightsType, FlightDetails } from "types/flightDetails";
import getMileageProgram from "api/mileageProgram/getMileageProgram";
import AirLinesCodesMock from "../../mocks/airLines.json";
import { useSearchOriginDestiny } from "contexts/searchOriginDestiny";
import AirlinesTicketsCard from "components/Cards/AirlinesTicketsCard";
import Loading from "components/Loading";
import PageTitle from "components/PageTitles";
import ArrowRightWhite from "../../assets/icons/arrows/arrow-right-gray.svg";
import postFavoriteFlight from "api/favoriteFlights/postFavoriteFlight";
import deleteFavoriteFlight from "api/favoriteFlights/deleteFavoriteFlight";
import { useSearchedFlight } from "contexts/useSearchedFlight";
import ListItemAlert from "components/Lists/ListItemAlert";
import Button from "components/Buttons/Button";
import { getAirportsByIATA } from "utils/getAirportsByIATA";
import BellAlert from "../../assets/icons/bells/bell.svg";
import Toast from "components/Toast";
import { IToast } from "types/toast";
import SplashPaint from "../../assets/icons/paint/splash-paint-orange.svg";
import getLinkToShare from "api/flightLink/getLinkToShare";
import { useModal } from "contexts/useModal";
import destination1 from "../../assets/images/offer-destinations-images/destination1.png";

import { IOptionInputTextSelectProps } from "components/Inputs/InputTextSelect";
import postUserAlerts, {
  PostUserAlertsBodyType,
} from "api/alerts/postUserAlerts";
import { useNavigate } from "react-router-dom";
import { useMilesPrograms } from "contexts/useMilesPrograms";

type IMileagePlans = {
  source: string;
  mileageProgram: string;
};

const FlightDetailsPage: React.FC = () => {
  const {
    setMainScreen,
    setPrincipalScreen,
    setSecondaryScreen,

    params,
  } = useDeskNavigation();
  const navigate = useNavigate();
  const { setModalContent, setOpenUseModal } = useModal();
  const { flightId, isAbleToPurchase } = params;
  const { userInfo } = useUserInfo();
  const { setOriginDestiny } = useSearchOriginDestiny();
  const { searchedFlight } = useSearchedFlight();
  const orderRef = useRef<HTMLDivElement>(null);
  const [flightSelected, setSelectedFlight] = useState<FlightDetails>(
    {} as FlightDetails
  );
  const [programs, setPrograms] = useState<
    { source: string; mileageProgram: string }[]
  >([]);
  const [dates, setDates] = useState([]);
  const [airLine, setAirLines] = useState<string[]>([]);
  const [creditCards, setCreditCards] = useState([]);
  const [points, setPoints] = useState([]);
  const [buy, setBuy] = useState([]);
  const [externalLink, setExternalLink] = useState("");
  const [selectedSource, setSelectedSource] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLikeLoading, setIsLikeLoading] = useState(false);
  const [isShareLoading, setIsShareLoading] = useState(false);
  const [routeTripLiked, setRouteTripLiked] = useState(false);
  const [isSoldOutRoute, setIsSoldOutRoute] = useState(false);
  const [destinationAirport, setDestinationAirport] = useState<string>("");
  const [originAirport, setOriginAirport] = useState<string>("");
  const [classesAvailable, setClassesAvailable] = useState<string[]>([]);
  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });

  const [pageWidthSize, setPageWidthSize] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setPageWidthSize(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    setRouteTripLiked(searchedFlight.isFavorite);
  }, [searchedFlight]);

  useEffect(() => {
    flightDetails();
    const getCityByIATA = async () => {
      const token = userInfo["@air-fly_access-token"];
      const destination = await getAirportsByIATA(
        token,
        searchedFlight.route.destinationAirport
      );
      const origin = await getAirportsByIATA(
        token,
        searchedFlight.route.originAirport
      );

      setDestinationAirport(destination);
      setOriginAirport(origin);
    };

    setClassesAvailable(
      [
        searchedFlight.YAvailable ? "economic" : null,
        searchedFlight.WAvailable ? "premium" : null,
        searchedFlight.JAvailable ? "executive" : null,
        searchedFlight.FAvailable ? "first" : null,
      ].filter(Boolean) as ClassesFlightsType[]
    );

    getCityByIATA();
  }, [flightId]);

  const createAlert = async () => {
    try {
      const seatType = [
        searchedFlight.YAvailable ? "Y" : null,
        searchedFlight.WAvailable ? "W" : null,
        searchedFlight.JAvailable ? "J" : null,
        searchedFlight.FAvailable ? "F" : null,
      ].filter(Boolean) as ClassesFlightsType[];

      const prepareParams: PostUserAlertsBodyType = {
        classes: seatType,
        date: searchedFlight.date,
        destination: searchedFlight.route.destinationAirport,
        origin: searchedFlight.route.originAirport,
        direct: undefined,
      };

      const request = await postUserAlerts(
        userInfo["@air-fly_access-token"],
        prepareParams
      );

      switch (request.status) {
        case 201:
          setToastConfig({
            description: "Your alert has been successfully created",
            title: "Alert Added",
            type: "success",
          });

          setToastShow(true);
          setTimeout(() => {
            setToastShow(false);
          }, 4000);
          break;
        default:
          setToastConfig({
            description:
              "We had a problem making the request. Please try again",
            title: "Sorry",
            type: "error",
          });

          setToastShow(true);
          setTimeout(() => {
            setToastShow(false);
          }, 4000);
          break;
      }
    } catch (error) {
      console.error(error);
      setToastConfig({
        description: "We had a problem making the request. Please try again",
        title: "Sorry",
        type: "error",
      });

      setToastShow(true);
      setTimeout(() => {
        setToastShow(false);
      }, 4000);
    }
  };

  const formatToShortDate = (isoString: string): string => {
    const date = new Date(isoString);

    const options: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "numeric",
      timeZone: "UTC",
    };

    return date.toLocaleDateString("en-US", options);
  };

  const formatToShortDateWeek = (isoString: string): string => {
    const date = new Date(isoString);

    const options: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "numeric",
      timeZone: "UTC",
      weekday: "short",
    };

    return date.toLocaleDateString("en-US", options);
  };

  const flightDetails = async () => {
    setIsLoading(true);
    try {
      if (flightId) {
        const reqDetailsFlight = await getFlightDetailsById(
          userInfo["@air-fly_access-token"],
          flightId
        );

        if (reqDetailsFlight.status === 200) {
          setSelectedFlight(reqDetailsFlight.res);
          setIsSoldOutRoute(
            reqDetailsFlight.res?.data?.length > 0 &&
              flightSelected.data?.filter((flight) => flight.RemainingSeats > 0)
                ?.length > 0
          );

          const airLineAcronym = reqDetailsFlight.res.data?.map(
            (al: any) => al.Carriers.split(",")[0]
          );

          const destinationCoordinates =
            reqDetailsFlight.res.destination_coordinates;
          const originCoordinates = reqDetailsFlight.res.origin_coordinates;

          const reqMilesPrograms = await getMileageProgram(
            userInfo["@air-fly_access-token"]
          );

          setOriginDestiny((prevState) => ({
            ...prevState,
            destiny: {
              ...prevState.destiny,
              lat: destinationCoordinates?.Lat,
              lon: destinationCoordinates?.Lon,
            },
            origin: {
              ...prevState.origin,
              lat: originCoordinates?.Lat,
              lon: originCoordinates?.Lon,
            },
          }));

          setAirLines(processAirlineCodes(removeDuplicates(airLineAcronym)));

          setDates(
            Array.from(
              new Set(
                reqDetailsFlight.res.data?.map((airLines: any) =>
                  formatToShortDate(airLines.DepartsAt).replace(", ", "-")
                )
              )
            )
          );

          const mileageProgramArr = reqDetailsFlight.res.data?.map(
            (airLines: any) => airLines.Source
          );
          const programsFiltered = reqMilesPrograms.res?.filter(
            (program: IMileagePlans) =>
              mileageProgramArr?.includes(program.source)
          );
          setPrograms(programsFiltered);
          selectedMilesProgram(programsFiltered[0]?.source);
          setSelectedSource(programsFiltered[0]?.source);
        }
      }
    } catch (error) {
      console.error(error);
      throw new Error(`Error: ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const purchaseHandler = () => {
    if (externalLink) {
      window.open(externalLink, "_blank", "noopener,noreferrer");
    } else {
      window.open(
        flightSelected?.booking_links[0]?.link,
        "_blank",
        "noopener,noreferrer"
      );
    }
  };

  const searchMilesyHandler = () => {
    setMainScreen("");
    setPrincipalScreen("/search-milesy");
    setSecondaryScreen("/flight-details", { isAbleToPurchase: "true" });
    navigate("/home");
  };

  const removeDuplicates = (arr: string[]) => {
    return arr?.filter((item, index) => arr.indexOf(item) === index);
  };

  const processAirlineCodes = (arr: string[]) => {
    const uniqueCodes = arr?.filter(
      (item, index) => arr.indexOf(item) === index
    );
    //@ts-ignore
    const names = uniqueCodes?.map((code) => AirLinesCodesMock[code] || code);

    return names;
  };

  const selectedMilesProgram = (program: {
    source: string;
    mileageProgram: string;
  }) => {
    setExternalLink(
      flightSelected.booking_links?.find((link) =>
        link.label?.includes(program?.mileageProgram)
      )?.link ?? ""
    );
  };

  if (isLoading) {
    return (
      <div className="flight-details__loading">
        <Loading />
      </div>
    );
  }

  const normalizeSubtitle = () => {
    if (searchedFlight) {
      const airportOrigin = searchedFlight.route.originAirport;
      const airportDestination = searchedFlight.route.destinationAirport;

      return (
        <p className="flight-details__page-title--subtitle no-margin">
          {airportOrigin} <img src={ArrowRightWhite} alt="arrow right white" />
          &nbsp; {airportDestination}
        </p>
      );
    } else {
      return "";
    }
  };

  const dislikeRouteTripHandler = async () => {
    if (isLikeLoading) return;

    setIsLikeLoading(true);
    setRouteTripLiked(false);

    try {
      const request = await deleteFavoriteFlight(
        userInfo["@air-fly_access-token"],
        flightId
      );

      switch (request.status) {
        case 204:
          // setRouteTripLiked(false);
          break;

        default:
          setRouteTripLiked(true);

          break;
      }
    } catch (error) {
      console.error(error);
      setRouteTripLiked(true);
    } finally {
      setIsLikeLoading(false);
    }
  };

  const likeRouteTripHandler = async () => {
    if (isLikeLoading) return;
    setIsLikeLoading(true);
    setRouteTripLiked(true);

    try {
      const request = await postFavoriteFlight(
        userInfo["@air-fly_access-token"],
        flightId
      );

      switch (request.status) {
        case 201:
          break;
        case 409:
          dislikeRouteTripHandler();
          break;
        default:
          setRouteTripLiked(false);

          break;
      }
    } catch (error) {
      console.error(error);
      setRouteTripLiked(false);
    } finally {
      setIsLikeLoading(false);
    }
  };

  const likeDislikeFlight = () => {
    if (routeTripLiked) {
      dislikeRouteTripHandler();
    } else {
      likeRouteTripHandler();
    }
  };

  const handleTryAgain = () => {
    setOpenUseModal(false);
  };

  const createAlertHandler = () => {
    if (userInfo["@air-fly_user-plan"] === "global") {
      createAlert();
    } else {
      setModalContent(
        <section className="payment-response">
          <span className="icon icon-airplane icon-md icon-orange "></span>
          <h5 className="no-margin margin-top-16">
            <b>Oops, you need to upgrade your plan!</b>
          </h5>
          {/* <p className="small-p margin-bottom-16">
							Unlock all filters with the AirFly Ultimate Plan! Use the promo
							code: "AMIGOS" for 30 days trial.
						</p> */}
          <div className="buttons-container">
            <Button
              children={"Upgrade Now"}
              buttonStyle={"primary"}
              onClick={() => {
                handleTryAgain();
              }}
            />
            <Button
              children={"Continue Free"}
              buttonStyle={"tertiary"}
              onClick={() => {
                setOpenUseModal(false);
              }}
            />
          </div>
        </section>
      );
      setOpenUseModal(true);
    }
  };

  const copyRouteId = (routeId: string) => {
    try {
      navigator.clipboard.writeText(routeId);
    } catch (error) {
      console.error(error);
      setToastConfig({
        title: "Oops...",
        type: "error",
        description: "We had a problem trying to copy the link",
      });
      setToastShow(true);

      setTimeout(() => {
        setToastShow(false);
      }, 5000);
    }
  };

  const generateRouteLink = async () => {
    if (isShareLoading) return;
    setIsShareLoading(true);
    try {
      const request = await getLinkToShare(
        userInfo["@air-fly_access-token"],
        searchedFlight.id
      );

      switch (request.status) {
        case 200:
          copyRouteId(request.res.url);
          setToastConfig({
            title: "Success",
            type: "success",
            description: "Link copied successfully",
          });
          setToastShow(true);

          setTimeout(() => {
            setToastShow(false);
          }, 5000);
          break;
      }
    } catch (error) {
      console.error(error);
    }
    setIsShareLoading(false);
  };

  return (
    <div className="flight-details">
      <PageTitle
        subtitle={normalizeSubtitle()}
        title="Flight Details"
        backgroundColor="chalk"
        onBackClick={() => setSecondaryScreen("")}
        buttonWhite
        buttonToLike
        likeButtonAction={likeDislikeFlight}
        liked={routeTripLiked}
        className="flight-details__page-title"
        buttonCopy
        buttonCopyAction={generateRouteLink}
      />
      <main
        className="flight-details-main
			 		padding-left-mb-16 padding-bottom-16 
			 		padding-right-16"
      >
        {flightSelected?.data?.length > 0 &&
        flightSelected.data?.filter((flight) => flight.RemainingSeats > 0)
          ?.length > 0 ? (
          <AirlinesTicketsCard
            tripData={flightSelected?.data}
            programs={programs}
            selectProgramCallBack={selectedMilesProgram}
            searchedFlight={searchedFlight}
            coverImage={destination1}
          />
        ) : (
          <div className="sold-out-notice">
            <div className="sold-out-notice__card">
              <img src={SplashPaint} alt="splash paint" />
              <h2 className="card__title">Oops, flight not found!</h2>
              <p className="card__explanation-text">
                There are no flights available for this route at the moment. You
                can set an alert to be notified when flights from&nbsp;
                <span>
                  {originAirport} ({searchedFlight?.route?.originAirport})
                </span>{" "}
                to{" "}
                <span>
                  {destinationAirport} (
                  {searchedFlight?.route?.destinationAirport})
                </span>{" "}
                become available again.
              </p>
              <ListItemAlert
                date={formatToShortDateWeek(searchedFlight?.date)}
                from={searchedFlight?.route?.originAirport}
                to={searchedFlight?.route?.destinationAirport}
                iconLeft={BellAlert}
                typeClass={classesAvailable}
                type="Stops"
                hasArrow
                className="card__list-item margin-vert-16"
              />

              <Button buttonStyle="primary" onClick={createAlertHandler}>
                Create Alert
              </Button>
            </div>
          </div>
        )}
        {flightSelected?.data?.length > 0 &&
        flightSelected.data?.filter((flight) => flight.RemainingSeats > 0)
          ?.length > 0 ? (
          <OrderReview
            isAbleToPurchase={
              isAbleToPurchase && isAbleToPurchase === "true" ? true : false
            }
            ref={orderRef}
            order={{
              airline: airLine.join(", "),
              buy: buy.join(", "),
              credit_card: creditCards.join(", "),
              dates: dates.join(", "),
              points: points.join(", "),
              program: programs[0]?.mileageProgram,
            }}
            purchaseAction={() => {
              if (isAbleToPurchase && isAbleToPurchase === "true") {
                purchaseHandler();
              } else {
                searchMilesyHandler();
              }
            }}
          />
        ) : (
          false
        )}
      </main>

      <Toast
        type={toastConfig.type}
        isVisible={toastShow}
        setIsVisible={setToastShow}
        title={toastConfig.title}
        description={toastConfig.description}
      />
    </div>
  );
};

export default FlightDetailsPage;
