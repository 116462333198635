import { createContext, useState, ReactNode } from "react";

interface ButtonDealContextProps {
  showButtonDeal: boolean;
  setShowButtonDeal: (open: boolean) => void;
}

export const ButtonDealContext = createContext({} as ButtonDealContextProps);

export const ButtonDealProvider = ({ children }: { children: ReactNode }) => {
  const [showButtonDeal, setShowButtonDeal] = useState(false);

  return (
    <ButtonDealContext.Provider value={{ setShowButtonDeal, showButtonDeal }}>
      {children}
    </ButtonDealContext.Provider>
  );
};
