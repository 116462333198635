import React, { useEffect, useState } from "react";
import "./styles.scss";
import DropDown, { DropDownElement } from "components/DropDown";
import Button from "components/Buttons/Button";
import ArrowUpDown from "../../assets/icons/arrows/bx-transfer-orange.svg";
import FilterIcon from "../../assets/icons/filter/filter-icon-orange.svg";
import { useModal } from "contexts/useModal";
import { useUserInfo } from "contexts/userInfo";
import getAirportsCodes from "api/flights/getAirportsCodes";
import InputTextSelect, {
  IOptionInputTextSelectProps,
} from "components/Inputs/InputTextSelect";
import { IAirportsSearch } from "types/airportsSearch";
import { useDeskNavigation } from "contexts/useDeskNavigation";
import { useSearchOriginDestiny } from "contexts/searchOriginDestiny";
import SlidDownFilterFlights, {
  SlidDownInfo,
} from "components/Modal/SlidDownFilterFlights";
import { searchFilteredConsultCallback } from "utils/searchFilteredConsultCallback";
import { useUserNearbyAirports } from "hooks/useNearbyAirports";
import { FilterFlightObj } from "types/filter";
import { IOriginDestiny } from "types/originDestiny";
import airlines from "mocks/airLines.json";

interface SearchFlightFilterBarProps {
  className?: string;
}

const SearchFlightFilterBar: React.FC<SearchFlightFilterBarProps> = (props) => {
  const { className } = props;

  const [isSlideDownVisible, setIsSlidDownVisible] = useState(false);
  const [inputSearchResultOptions, setInputSearchResultOptions] = useState(
    {} as IOptionInputTextSelectProps[]
  );
  const [inputSearchCompanies, setInputSearchCompanies] = useState(
    {} as IOptionInputTextSelectProps[]
  );
  const { openUseModal, setModalContent, setOpenUseModal } = useModal();
  const [departure, setDeparture] = useState("");
  const [destination, setDestination] = useState("");
  const [company, setCompany] = useState("");
  const { userInfo } = useUserInfo();
  const {
    setMainScreen,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
  } = useDeskNavigation();
  const { setOriginDestiny } = useSearchOriginDestiny();
  const [departureOptions, setDepartureOptions] = useState("");
  const [destinationOptions, setDestinationOptions] = useState<
    DropDownElement[]
  >([]);
  const [slidDownFilters, setSlidDownFilters] = useState<SlidDownInfo>(
    {} as SlidDownInfo
  );

  const [inputDestination, setInputDestination] = useState("");
  const [inputDeparture, setInputDeparture] = useState("");
  const [departureInputWarning, setDepartureInputWarning] = useState(false);
  const [inputCompany, setInputCompany] = useState("");
  const { nearbyAirports } = useUserNearbyAirports();
  const [destinationInputWarning, setDestinationInputWarning] = useState(false);
  useEffect(() => {
    setDeparture(JSON.stringify(nearbyAirports[0]));

    setInputDeparture(
      `${nearbyAirports[0]?.iata} - ${nearbyAirports[0]?.name}`
    );
  }, []);

  const handleInputChange = async (search: string) => {
    try {
      const request = await getAirportsCodes(
        userInfo["@air-fly_access-token"],
        {
          search,
        }
      );

      setInputSearchResultOptions(
        request.res.data.map((airport: IAirportsSearch) => {
          return {
            label: `${airport.iata} - ${airport.name}`,
            detailsText: `${airport.city}, ${airport.country}`,
            value: JSON.stringify(airport),
          } as IOptionInputTextSelectProps;
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleTryAgain = () => {
    setOpenUseModal(false);
    setMainScreen("");
    setPrincipalScreen("/user-settings-subscriptions");
    setSecondaryScreen("");
    setTertiaryScreen("");
  };

  const filterFlightsHandler = () => {
    try {
      const departureParse = JSON.parse(departure);
      let destinationCoord = {
        lat: 0,
        lon: 0,
      };

      const destinationParse = JSON.parse(destination);
      if (destinationParse) {
        destinationCoord = {
          lat: destinationParse.latitude,
          lon: destinationParse.longitude,
        };
      }

      const departureCoord = {
        lat: departureParse.latitude,
        lon: departureParse.longitude,
      };

      const prepareDataObj: FilterFlightObj = {
        destinationIATA: destinationParse.iata || "",

        departureIATA: departureParse.iata,

        departureCoord: departureCoord,

        destinationCoord: destinationCoord,

        classSelected: slidDownFilters.classSelected || "",
        dateSelected: slidDownFilters.dateSelected || "",
        paymentMethod: "",
        stopsSelected: slidDownFilters.stopsSelected || "",
      };

      searchFilteredConsultCallback(prepareDataObj, setOriginDestiny);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAirlinesChange = (search: string) => {
    const filtered = Object.entries(airlines).filter(
      ([code, name]) =>
        code.toLowerCase().includes(search) ||
        name.toLowerCase().includes(search)
    );

    setInputSearchCompanies(
      filtered.map(([_, name]) => {
        return {
          label: name,
          value: name,
        } as IOptionInputTextSelectProps;
      })
    );
  };

  return (
    <nav id="search-flight-filter-bar" className={className ?? ""}>
      {/* <DropDown
        dropType="click-select"
        options={departureOptions}
        setValueCallBack={departureCallback}
        placeholder="Select Departure"
        inputValueSet={}
      /> */}

      <InputTextSelect
        options={inputSearchResultOptions}
        onSelect={setDeparture}
        inputValue={inputDeparture}
        setInputValue={setInputDeparture}
        onChangeInput={handleInputChange}
        inputPlaceholder={"Select Departure"}
        isWarning={departureInputWarning}
      />

      <button className="circle-button arrows">
        <img src={ArrowUpDown} alt="arrows" />
      </button>
      <InputTextSelect
        options={inputSearchResultOptions}
        onSelect={setDestination}
        inputValue={inputDestination}
        setInputValue={setInputDestination}
        onChangeInput={handleInputChange}
        inputPlaceholder={"Select Destination"}
        isWarning={destinationInputWarning}
        className="margin-right-16"
      />

      <InputTextSelect
        options={inputSearchCompanies}
        onSelect={setCompany}
        inputValue={inputCompany}
        setInputValue={setInputCompany}
        onChangeInput={handleAirlinesChange}
        inputPlaceholder={"Select Company"}
        isWarning={destinationInputWarning}
        className="margin-right-16"
      />

      <button
        onClick={() => setIsSlidDownVisible(true)}
        className="circle-button filter margin-right-16"
      >
        <img src={FilterIcon} alt="filter icon" />
      </button>
      <Button buttonStyle="primary" onClick={filterFlightsHandler}>
        Search
      </Button>
      <SlidDownFilterFlights
        closeSlidDownAreaCallback={setIsSlidDownVisible}
        isOpen={isSlideDownVisible}
        filterResultsCallback={setSlidDownFilters}
      />
    </nav>
  );
};

export default SearchFlightFilterBar;
