import React, { ReactNode, useEffect, useState } from "react";
import "./styles.scss";

interface InputListSelectProps {
  className?: string;
  elements: InputListSelectElement[];
  onChangeValueCallback: (a: string[]) => void;
  defaultValueProp: string[];
}

interface InputListSelectElement {
  inputLabel: string;
  subText?: string;
  inputValue: string;
  groupName: string;
  icon?: string;
  node?: ReactNode;
}

const InputListSelect: React.FC<InputListSelectProps> = (props) => {
  const { className, elements, onChangeValueCallback, defaultValueProp } =
    props;

  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    undefined
  );
  const [inputValues, setInputsValues] = useState<string[]>(
    defaultValueProp || []
  );

  useEffect(() => {
    setInputsValues(defaultValueProp || []);
  }, [defaultValueProp]);

  const selectElementHandler = (element: string) => {
    setSelectedValue(element);
    onChangeValueCallback([element]);
  };

  return (
    <div id="input-list-select" className={className ?? ""}>
      <ul className="input-list-select__ul no-padding">
        {elements.map((element, index, arr) => (
          <li
            className={`input-list-select__li padding-hor-16 ${
              index === 0 ? "first-child" : "" // Yeah, I know
            }`}
            key={index}
            onClick={() => selectElementHandler(element.inputValue)}
          >
            <span
              className={`icon ${element.icon} icon-gray-light icon-sm
							li__icon-left padding-right-8`}
            />

            <p className="li__text no-margin">{element.inputLabel}</p>
            {element.subText ? (
              <p className="li__sub-text no-margin">{element.subText}</p>
            ) : (
              false
            )}

            <div className="li__node">{element.node}</div>

            {arr.length > index + 1 ? <div className="separator" /> : false}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InputListSelect;
