import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { IAirportsSearch } from "types/airportsSearch";

interface UserNearbyAirportsContextProps {
  nearbyAirports: IAirportsSearch[];
  setNearbyAirports: (airports: IAirportsSearch[]) => void;
}

const UserNearbyAirportsContext = createContext<
  UserNearbyAirportsContextProps | undefined
>(undefined);

const UserNearbyAirportsProvider = ({ children }: { children: ReactNode }) => {
  const nearbyStorage = localStorage.getItem("@air-fly_nearby");

  const nearbyStorageParsed: IAirportsSearch[] = nearbyStorage
    ? JSON.parse(nearbyStorage)
    : [];

  const [nearbyAirports, setNearbyAirports] =
    useState<IAirportsSearch[]>(nearbyStorageParsed);

  useEffect(() => {
    if (nearbyAirports.length) {
      localStorage.setItem("@air-fly_nearby", JSON.stringify(nearbyAirports));
    }
  }, [nearbyAirports]);

  return (
    <UserNearbyAirportsContext.Provider
      value={{ nearbyAirports, setNearbyAirports }}
    >
      {children}
    </UserNearbyAirportsContext.Provider>
  );
};

const useUserNearbyAirports = () => {
  const context = useContext(UserNearbyAirportsContext);

  if (context === undefined) {
    throw new Error(
      "useUserNearbyAirports must be used within a UserNearbyAirportsProvider"
    );
  }

  return context;
};

export { UserNearbyAirportsProvider, useUserNearbyAirports };
