import React, { ReactNode, useEffect, useRef, useState } from "react";
import "./styles.scss";
import ChevronDown from "../../assets/icons/arrows/bx-chevron-down.svg";
import ChevronUp from "../../assets/icons/arrows/bx-chevron-up.svg";

import InputText from "components/Inputs/InputText";
import InputRadio from "components/Inputs/InputRadio";
import InputListSelect from "components/Inputs/InputListSelect";
import Button from "components/Buttons/Button";

interface DropDownProps {
  className?: string;
  options: DropDownElement[];
  setValueCallBack: (option: string[]) => void;
  placeholder?: string;
  iconLeft?: string;
  label?: string;
  textButton?: string;
  dropType: "radio" | "checkbox" | "click-select";
  buttonCallBack?: () => void;
  inputValueSet?: string | string[];
  defaultValueProp?: string | string[] | undefined;
  elementNode?: ReactNode;
  readOnly?: boolean;
}

export interface DropDownElement {
  inputLabel: string;
  inputValue: string;
  groupName: string;
  icon?: string;
  node?: ReactNode;
  subText?: string;
}

const DropDown: React.FC<DropDownProps> = (props) => {
  const {
    className,
    options,
    setValueCallBack,
    placeholder,
    iconLeft,
    label,
    textButton,
    dropType,
    buttonCallBack,
    inputValueSet,
    defaultValueProp,
    elementNode,
    readOnly,
  } = props;

  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [inputValue, setInputValue] = useState<string | string[]>("");
  const dropDownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropDownRef.current &&
      !dropDownRef.current.contains(event.target as Node)
    ) {
      setIsOpenDropDown(false);
    }
  };

  const toggleOpenDropDown = () => {
    setIsOpenDropDown((prev) => !prev);
  };

  const selectOption = (option: string[]) => {
    setInputValue(option);
    setValueCallBack(option);

    if (dropType === "click-select") {
      setIsOpenDropDown(false);
    }
  };

  const getDisplayValue = () => {
    if (dropType === "checkbox" && Array.isArray(inputValue)) {
      return inputValue.join(", ");
    }

    return typeof inputValue === "string" ? inputValue : inputValue[0];
  };

  const [isTransitionComplete, setIsTransitionComplete] = useState(false);

  useEffect(() => {
    const element = inputRadioRef.current;
    let transitionsCompleted = 0;

    if (inputValueSet) {
      setInputValue(inputValueSet);
    }

    if (!element) return;

    const handleTransitionEnd = () => {
      transitionsCompleted += 1;

      const transitionProperties =
        getComputedStyle(element).transitionProperty.split(", ").length;
      if (transitionsCompleted >= transitionProperties) {
        setIsTransitionComplete(true);
        transitionsCompleted = 0;
      } else {
        setIsTransitionComplete(false);
      }
    };

    element.addEventListener("transitionend", handleTransitionEnd);

    return () => {
      element.removeEventListener("transitionend", handleTransitionEnd);
    };
  }, []);

  const inputRadioRef = useRef<HTMLDivElement>(null);
  const inputCheckboxRef = useRef<HTMLDivElement>(null);

  return (
    <div
      id="drop-down-component"
      className={className ?? ""}
      data-active={isOpenDropDown}
      ref={dropDownRef}
    >
      <InputText
        label={label}
        value={getDisplayValue()}
        onClick={toggleOpenDropDown}
        readOnly={readOnly}
        inputType="text"
        className={`drop-down-component__input`}
        placeholder={placeholder}
        iconRight={isOpenDropDown ? ChevronUp : ChevronDown}
        iconLeft={iconLeft}
        textTransform={"capitalize"}
      />
      <div
        ref={inputRadioRef}
        className={`drop-down-component__input-container ${
          isOpenDropDown
            ? "drop-down-component__input-container-open"
            : "drop-down-component__input-container-close"
        }`}
      >
        {dropType === "radio" ? (
          <InputRadio
            elements={options}
            onChangeValueCallback={selectOption}
            defaultValueProp={defaultValueProp}
            className={`${isTransitionComplete ? "active" : ""}`}
          />
        ) : dropType === "checkbox" ? (
          <></>
        ) : // <InputCheckbox
        //   // ref={inputCheckboxRef}
        //   elements={options}
        //   onChangeValueCallback={selectOption}
        //   // className={`padding-top-24`}
        //   defaultValueProp={defaultValueProp as string[]}
        // />
        dropType === "click-select" ? (
          <InputListSelect
            elements={options}
            onChangeValueCallback={selectOption}
            defaultValueProp={defaultValueProp as string[]}
            className={`${isTransitionComplete ? "active" : ""}`}
          />
        ) : null}

        {textButton && buttonCallBack ? (
          <div className="drop-down__container-button">
            <Button buttonStyle="tertiary" onClick={buttonCallBack}>
              {textButton}
            </Button>
          </div>
        ) : (
          false
        )}
      </div>
    </div>
  );
};

export default DropDown;
