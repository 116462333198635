import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from "react";
import "./styles.scss";
import { FlightAvailability } from "types/flights";
import { useUserInfo } from "contexts/userInfo";
import { useDeskNavigation } from "contexts/useDeskNavigation";
import { useSearchOriginDestiny } from "contexts/searchOriginDestiny";
import AirlinesSimpleTickets from "components/Cards/ArlinesSimpleTicket";
import getFlights from "api/flights/getFlights";
import { usePinsAirports } from "contexts/usePinsAirports";
import getAirportsCodes from "api/flights/getAirportsCodes";
import { useSearchedFlight } from "contexts/useSearchedFlight";
import Button from "components/Buttons/Button";
import { useModal } from "contexts/useModal";
import InputRadioSub from "components/Inputs/InputRadioSub";
import { subscriptionOptions } from "mocks/subscriptionPlans";
import infoIcon from "../../assets/icons/info/union-circle-gray.svg";
import AirplaneOrange from "../../assets/icons/airPlanes/airplane-orange.svg";
import postSendLinkEmailToVerify from "api/emailVerification/postSendLinkEmailToVerify";
import Loading from "components/Loading";
import { IToast } from "types/toast";
import Toast from "components/Toast";
import CheckCircleGreen from "../../assets/icons/checks/check-circle-green.svg";
import ErrorCheck from "../../assets/icons/close/close-icon-pink.svg";
import { ClassesFlightsType } from "types/flightDetails";
import { useNavigate } from "react-router-dom";
import { clearLocalStorage } from "utils/clearLocalStorage";
import TextActionCard from "components/Cards/TextActionCard";
import SplashPaint from "../../assets/icons/paint/splash-paint-orange.svg";
import { getAirportsByIATA } from "utils/getAirportsByIATA";
import ListItemAlert from "components/Lists/ListItemAlert";
import BellAlert from "../../assets/icons/bells/bell-gray.svg";
import ClockOrange from "../../assets/icons/clocks/clock-icon-orange.svg";
import getUserAlerts from "api/alerts/getUserAlerts";
import { IAlerts } from "types/alerts";
import { ButtonDealContext } from "contexts/buttonDealContext";

const SearchMilesyPage: React.FC = () => {
  const { userInfo } = useUserInfo();
  const navigate = useNavigate();
  const { setModalContent, setOpenUseModal } = useModal();
  const { setSearchedFlight } = useSearchedFlight();
  const { originDestiny } = useSearchOriginDestiny();
  const { setAirportsPins } = usePinsAirports();
  const { setSecondaryScreen, setPrincipalScreen } = useDeskNavigation();
  const [flightsList, setFlights] = useState<FlightAvailability[]>([]);
  const [skipReq, setSkipReq] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const observer = useRef<IntersectionObserver | null>(null);
  const [hasNoFlights, setHasNoFlights] = useState(false);
  const [selectedFlightId, setSelectedFlightId] = useState("");
  const [chosenType, setChosenType] = useState("basic");
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });
  const [toastShow, setToastShow] = useState(false);
  const [destinationAirport, setDestinationAirport] = useState<string>("");
  const [originAirport, setOriginAirport] = useState<string>("");
  const [restFlightsToSee, setRestFlightsToSee] = useState(0);
  const [noticeUserPlan, setNoticeUserPlan] = useState(false);
  const [alertList, setAlertList] = useState<string[]>([]);
  const { setShowButtonDeal } = useContext(ButtonDealContext);

  useEffect(() => {
    setShowButtonDeal(true);
  }, []);

  const resetAndFetchFlights = useCallback(() => {
    setFlights([]);
    setSkipReq(0);
    setHasMore(true);
    setHasNoFlights(false);
    getCityByIATA();

    listFlights(true);
  }, [
    originDestiny.destiny.iata,
    originDestiny.destiny.classes,
    originDestiny.destiny.date,
    originDestiny.destiny.direct,
  ]);

  useEffect(() => {
    resetAndFetchFlights();
    getNoticeTime();
    userAlertList();
  }, [resetAndFetchFlights]);

  const getCityByIATA = async () => {
    if (originDestiny.destiny.iata) {
      const destination = await getAirportsByIATA(
        userInfo["@air-fly_access-token"],
        originDestiny.destiny.iata
      );
      setDestinationAirport(destination);
    }

    if (originDestiny.origin.iata) {
      const origin = await getAirportsByIATA(
        userInfo["@air-fly_access-token"],
        originDestiny.origin.iata
      );

      setOriginAirport(origin);
    }
  };

  const minMilesValue = (flight?: FlightAvailability) => {
    if (flight) {
      const minMileageCost = Math.min(
        ...(flight
          ? [
              Number(flight.WMileageCost || Infinity),
              Number(flight.YMileageCost || Infinity),
              Number(flight.JMileageCost || Infinity),
              Number(flight.FMileageCost || Infinity),
            ]
          : [Infinity])
      );

      return minMileageCost;
    }
  };

  const sumSeats = (flight?: FlightAvailability) => {
    const seats =
      (flight?.YDirectRemainingSeats || 0) +
      (flight?.WDirectRemainingSeats || 0) +
      (flight?.JDirectRemainingSeats || 0) +
      (flight?.FDirectRemainingSeats || 0);
    return seats;
  };

  const showClass = (flight?: FlightAvailability) => {
    if (flight) {
      const seatType = [
        flight.YAvailable ? "economic" : null,
        flight.WAvailable ? "premium" : null,
        flight.JAvailable ? "executive" : null,
        flight.FAvailable ? "first" : null,
      ].filter(Boolean) as ClassesFlightsType[];

      return `
				<div class='no-margin search-milesy-seat-type'>
					<div class='dots'>
						<span class="dot-ticket ${
              seatType.includes("economic") ? "highlight" : ""
            }"></span>
						<span class="dot-ticket ${
              seatType.includes("premium") ? "highlight" : ""
            }"></span>
						<span class="dot-ticket ${
              seatType.includes("executive") ? "highlight" : ""
            }"></span>
						<span class="dot-ticket ${
              seatType.includes("first") ? "highlight" : ""
            }"></span>
					</div>
				</div>`;
    }
  };

  const listFlights = useCallback(
    async (reset = false) => {
      if (isLoading || (!hasMore && !reset)) return;
      setIsLoading(true);

      const params: Record<string, any> = {};

      if (!reset && skipReq) {
        params.skip = skipReq;
      }

      if (originDestiny.destiny.iata) {
        params.destination = originDestiny.destiny.iata;
      }

      if (originDestiny.origin.iata) {
        params.origin = originDestiny.origin.iata;
      }

      if (originDestiny.destiny.direct) {
        params.direct = originDestiny.destiny.direct;
      }

      if (originDestiny.destiny.date) {
        params.date = originDestiny.destiny.date;
      }

      if (originDestiny.destiny.classes) {
        params.classes = originDestiny.destiny.classes;
      }

      params.take = 15;

      try {
        // const request = await getFlightsLocation(
        const request = await getFlights(
          userInfo["@air-fly_access-token"],
          params
        );

        if (request.status === 200) {
          const newFlights: FlightAvailability[] = request.res.data;

          const isFavoriteList = request.res.data.map(
            //@ts-ignore
            (flight) => flight.isFavorite
          );
          if (reset) {
            setFlights(newFlights);
            const pinsObj = newFlights.map(
              (airport) => airport.route.destinationAirport
            );

            const infoAirport = await Promise.all(
              pinsObj.map((iataCode) =>
                getAirportsCodes(userInfo["@air-fly_access-token"], {
                  iata: iataCode,
                })
              )
            );

            const coordinatesFromInfoAirport = infoAirport.map((info) => {
              return {
                htmlToolTip: `
								<div class="tooltip-map-route">
									<p>${
                    newFlights.find(
                      (flight) =>
                        flight.route.destinationAirport ===
                        info.res.data[0].iata
                    )?.YMileageCost
                  }</p>
								</div>`,
                htmlPopUp: `
								<div class="pop-up-map-route">
									<div class="pop-up-map-route__column">
										<p>CLASS</p>
										<span class="column__spheres-container">${showClass(
                      newFlights.find(
                        (flight) =>
                          flight.route.destinationAirport ===
                          info.res.data[0].iata
                      )
                    )}</span>
									</div>
									<div class="pop-up-map-route__column">
										<p>SEATS</p>
										<p>${sumSeats(
                      newFlights.find(
                        (flight) =>
                          flight.route.destinationAirport ===
                          info.res.data[0].iata
                      )
                    )}</p>
									</div>
									<div class="pop-up-map-route__column">
										<p>POINTS</p>
										<p>${minMilesValue(
                      newFlights.find(
                        (flight) =>
                          flight.route.destinationAirport ===
                          info.res.data[0].iata
                      )
                    )}</p>
									</div>
								</div>`,
                lat: info.res.data[0].latitude,
                long: info.res.data[0].longitude,
              };
            });

            setAirportsPins(coordinatesFromInfoAirport);
          } else {
            setFlights((prev) => [...prev, ...newFlights]);
          }

          const totalFlights = request.res.total;
          const currentFlightsCount = reset
            ? newFlights.length
            : flightsList.length + newFlights.length;

          setHasMore(currentFlightsCount < totalFlights);
          setRestFlightsToSee(totalFlights);

          if (newFlights.length > 0) {
            setSkipReq((prev) => prev + 15);
          } else {
            setHasMore(false);
          }

          if (newFlights.length === 0) {
            setSecondaryScreen("");
            setHasNoFlights(true);
          }
        } else if (request.status === 401) {
          clearLocalStorage();
          navigate("/app");
        } else {
          setHasMore(false);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [hasMore, skipReq, userInfo, originDestiny.destiny, flightsList.length]
  );

  const lastFlightRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isLoading) return;

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          listFlights();
        }
      });

      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore, listFlights]
  );

  const selectFlightHandler = (id: string, flight: FlightAvailability) => {
    try {
      setSelectedFlightId(id);
      setSearchedFlight(flight);

      setSecondaryScreen(`/flight-details`, {
        flightId: id,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const verifyEmailHandler = async () => {
    setModalContent(
      <section className="loading-modal">
        <Loading />
      </section>
    );

    try {
      const request = await postSendLinkEmailToVerify(
        userInfo["@air-fly_access-token"]
      );

      switch (request.status) {
        case 200:
          setModalContent(modalUploadPlanOrVerifyEmail("send-email"));

          setTimeout(() => setOpenUseModal(false), 6000);
          break;
        default:
          setModalContent(modalUploadPlanOrVerifyEmail("error"));
          break;
      }
    } catch (error) {
      console.error(error);
      setOpenUseModal(false);
      setToastConfig({
        description: "We had a problem making the request. Please try again",
        title: "Sorry",
        type: "error",
      });

      setToastShow(true);
      setTimeout(() => {
        setToastShow(false);
      }, 4000);
    } finally {
      setIsLoading(false);
    }
  };
  const uploadPlanHandler = () => {};
  const handleOptionChange = (value: string) => {
    setChosenType(value);
  };

  const modalUploadPlanOrVerifyEmail = (
    status?: "error" | "send-email" | "email-already-verified"
  ) => {
    if (status === "error") {
      return (
        <section className="upload-plan-verify-email-modal">
          <img src={ErrorCheck} alt="error check" className="status-request" />

          <h2 className="upload-plan-verify-email-modal__title">
            Unable to verify your email.
          </h2>
          <p className="upload-plan-verify-email-modal__explanation-text">
            Please, check your credential and try again.
          </p>

          <Button onClick={() => setOpenUseModal(false)} buttonStyle="primary">
            Close
          </Button>
        </section>
      );
    } else if (status === "send-email") {
      return (
        <section className="upload-plan-verify-email-modal">
          <img src={CheckCircleGreen} alt="check" className="status-request" />

          <h5 className="upload-plan-verify-email-modal__title">
            Verification email sent successfully
          </h5>
          <p className="small-p upload-plan-verify-email-modal__explanation-text">
            Check your email inbox and click on the link we sent you. Also check
            your spam folder
          </p>
        </section>
      );
    } else if (status === "email-already-verified") {
      return (
        <section className="upload-plan-verify-email-modal">
          <h2 className="upload-plan-verify-email-modal__title">
            This email has already been verified
          </h2>
          <p className="upload-plan-verify-email-modal__explanation-text">
            When we have news about AirFly, you will be informed as soon as
            possible via the email provided
          </p>

          <Button onClick={() => setOpenUseModal(false)} buttonStyle="primary">
            Close
          </Button>
        </section>
      );
    } else if (!status && userInfo["@air-fly_user_role"] === "UNVERIFIED") {
      return (
        <section className="upload-plan-verify-email-modal">
          <img src={AirplaneOrange} alt="airplane" />
          <h2 className="upload-plan-verify-email-modal__title">
            Verify Email
          </h2>
          <p className="upload-plan-verify-email-modal__explanation-text">
            We need to verify your email so you can enjoy our app. There is no
            charge yet.
          </p>

          <div className="upload-plan-verify-email-modal__container-buttons">
            <Button
              buttonStyle="primary"
              onClick={verifyEmailHandler}
              disabled={isLoading}
            >
              Verify Email
            </Button>
            <p className="upload-plan-verify-email-modal__explanation-text verify-email">
              We will send you a like via email for verification
            </p>
            <Button
              className="margin-top-16"
              buttonStyle="quintenary"
              disabled={isLoading}
              onClick={() => setOpenUseModal(false)}
            >
              I'll do it later
            </Button>
          </div>
        </section>
      );
    } else if (!status) {
      <section className="upload-plan-verify-email-modal">
        <img src={AirplaneOrange} alt="airplane" />

        <h2 className="upload-plan-verify-email-modal__title">
          You’ve Reached Your Daily Update Limit!
        </h2>
        <p className="upload-plan-verify-email-modal__explanation-text">
          To keep receiving flight updates today, come back tomorrow or upgrade
          to one of our premium plans:
        </p>
        <section className="upload-plan-verify-email-modal__plans">
          {subscriptionOptions.map((option, index) => (
            <InputRadioSub
              key={index}
              title={option.title}
              price={option.price}
              description={option.description}
              value={option.value}
              icon={infoIcon}
              groupName={"options"}
              checked={option.value === chosenType}
              onChange={handleOptionChange}
              benefits={option.benefits}
            />
          ))}
        </section>

        <div className="upload-plan-verify-email-modal__container-buttons">
          <Button buttonStyle="primary" onClick={uploadPlanHandler}>
            Upgrade Now
          </Button>
          <Button
            buttonStyle="secondary"
            onClick={() => setOpenUseModal(false)}
          >
            Continue Free
          </Button>
        </div>
      </section>;
    }
  };

  const upgradePlanHandler = () => {
    setPrincipalScreen("/user-settings-subscriptions");
  };

  const createAlertHandler = () => {};
  const targetRef = useRef(null);

  useEffect(() => {
    if (userInfo["@air-fly_user-plan"] !== "global") {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (
            entry.isIntersecting &&
            !isLoading &&
            flightsList.length > 0 &&
            noticeUserPlan
          ) {
            setModalContent(
              <TextActionCard
                headerIcon={AirplaneOrange}
                className="upload-notice-modal"
                // transparentBackground
                principalText="You've reached your daily limit!"
                secondaryText={`To keep receiving flight updates today, come
									 back tomorrow or upgrade to one of our premium plans.`}
                buttons={[
                  {
                    buttonAction: () => {
                      upgradePlanHandler();
                      setOpenUseModal(false);
                    },
                    buttonStyle: "primary",
                    text: "UPGRADE NOW",
                  },
                  {
                    buttonAction: () => setOpenUseModal(false),
                    buttonStyle: "quintenary",
                    text: "CONTINUE FREE",
                  },
                ]}
              />
            );
            if (noticeUserPlan) {
              setOpenUseModal(true);
              localStorage.setItem(
                "@air-fly-is-user-notice",
                JSON.stringify(Date.now())
              );
              setNoticeUserPlan(false);
            }
          }
        },
        {
          threshold: 1.0,
        }
      );

      if (targetRef.current) {
        observer.observe(targetRef.current);
      }

      return () => {
        if (targetRef.current) {
          observer.unobserve(targetRef.current);
        }
      };
    }
  }, [flightsList.length, isLoading]);

  const getNoticeTime = () => {
    const storageTime = localStorage.getItem("@air-fly-is-user-notice");

    if (storageTime) {
      const time = new Date(Number(storageTime));
      const now = new Date();

      const diffInMilliseconds = now.getTime() - time.getTime();
      const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

      if (diffInHours > 24) {
        localStorage.setItem(
          "@air-fly-is-user-notice",
          JSON.stringify(Date.now())
        );
        setNoticeUserPlan(true);
      } else {
        setNoticeUserPlan(false);
      }
    } else {
      setNoticeUserPlan(true);
    }
  };

  const userAlertList = async () => {
    try {
      const request = await getUserAlerts(userInfo["@air-fly_access-token"]);
      const alerts: IAlerts[] = request.res;

      setAlertList(
        alerts
          .map((alert) => alert.notifications.map((alert) => alert.flightId))
          .flat()
      );
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="search-milesy-page">
      <main className="search-miles-page__main padding-16">
        {flightsList?.map((flight, index) => {
          const seatType = [
            flight.YAvailable ? "economic" : null,
            flight.WAvailable ? "premium" : null,
            flight.JAvailable ? "executive" : null,
            flight.FAvailable ? "first" : null,
          ].filter(Boolean) as ClassesFlightsType[];

          const isLastFlight = flightsList.length === index + 1;

          const filteredArray = Array.from(
            new Set(
              [
                flight.YMileageCost,
                flight.WMileageCost,
                flight.JMileageCost,
                flight.FMileageCost,
              ]
                .map((milesCost) => Number(milesCost))
                .filter((miles) => miles > 0)
            )
          );

          const priceToShow =
            filteredArray.length > 0 ? Math.min(...filteredArray) : 0;

          return (
            <div
              key={`${flight.id} - ${index}`}
              ref={isLastFlight ? lastFlightRef : null}
            >
              <AirlinesSimpleTickets
                isALikedFlight={flight.isFavorite}
                isANotifiedFlight={alertList.includes(flight.id)}
                isSelected={selectedFlightId === flight.id}
                cardClick={(id) => selectFlightHandler(id, flight)}
                ticket={{
                  id: flight.id,
                  price: priceToShow,
                  seatType,
                  destination: {
                    airport: flight.route.destinationAirport,
                    date: flight.date,
                    time: flight.date,
                  },
                  origin: {
                    airport: flight.route.originAirport,
                    date: flight.date,
                    time: flight.date,
                  },
                }}
              />
            </div>
          );
        })}

        {isLoading && (
          <div className="loading-container-search-milesy">
            <Loading />
          </div>
        )}
        {hasNoFlights && !isLoading && (
          <div className="sold-out-notice">
            <div className="sold-out-notice__card">
              <img src={SplashPaint} alt="splash paint" />
              <h2 className="card__title">Oops, flight not found!</h2>
              <p className="card__explanation-text">
                There are no flights available for this route at the moment. You
                can set an alert to be notified when flights from&nbsp;
                <span>
                  {originAirport} ({originDestiny.origin.iata})
                </span>{" "}
                to{" "}
                <span>
                  {destinationAirport} ({originDestiny.destiny.iata})
                </span>{" "}
                become available again.
              </p>
              <ListItemAlert
                // date={formatToShortDateWeek(searchedFlight.date)}
                date={""}
                from={originDestiny.origin.iata}
                to={originDestiny.destiny.iata}
                iconLeft={BellAlert}
                typeClass={[""]}
                type="Stops"
                hasArrow
                className="card__list-item margin-vert-16"
              />

              <Button buttonStyle="primary" onClick={createAlertHandler}>
                Create Alert
              </Button>
            </div>
          </div>
        )}
        <div ref={targetRef} />
        {!noticeUserPlan &&
        !isLoading &&
        userInfo["@air-fly_user-plan"] !== "global" ? (
          <TextActionCard
            headerIcon={ClockOrange}
            principalText="You've reached your daily limit!"
            secondaryText={
              <>
                To keep receiving flight updates today, come back tomorrow or{" "}
                <span
                  className="link-text"
                  onClick={() => {
                    upgradePlanHandler();
                    setOpenUseModal(false);
                  }}
                >
                  upgrade subscription
                </span>{" "}
                to one of our premium plans.
              </>
            }
          />
        ) : (
          <></>
        )}
      </main>
      <Toast
        type={toastConfig.type}
        isVisible={toastShow}
        setIsVisible={setToastShow}
        title={toastConfig.title}
        description={toastConfig.description}
      />
    </div>
  );
};

export default SearchMilesyPage;
