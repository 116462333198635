import React, { forwardRef } from "react";
import "./styles.scss";
import SubText from "components/Texts/SubText";
import Button from "components/Buttons/Button";

interface OrderReviewProps {
  className?: string;
  isAbleToPurchase?: boolean;
  order: {
    airline?: string;
    program?: string;
    credit_card?: string;
    points?: string;
    buy?: string;
    dates?: string;
  };
  purchaseAction: (a: any) => void;
  ref?: React.LegacyRef<HTMLDivElement> | undefined;
}

const OrderReview: React.FC<OrderReviewProps> = forwardRef((props, ref) => {
  const { order, purchaseAction, className, isAbleToPurchase } = props;

  return (
    <div id="order-review-component" className={className ?? ""} ref={ref}>
      <table className="order-review-table">
        <tbody className="order-review-tbody">
          <tr className="order-review-tr">
            <td className="order-review-td__key select-title">
              Flight Details
            </td>
          </tr>

          <tr className="order-review-tr">
            <td className="order-review-td__key">Programs</td>
            <td className="order-review-td__value">{order.program}</td>
          </tr>
          <tr className="order-review-tr">
            <td className="order-review-td__key">Dates</td>
            <td className="order-review-td__value">{order.dates}</td>
          </tr>
          <tr className="order-review-tr">
            <td className="order-review-td__key">Airline</td>
            <td className="order-review-td__value">{order.airline}</td>
          </tr>
        </tbody>
      </table>
      <div className="order-review-component__buttons-container">
        <Button buttonStyle="secondary" onClick={purchaseAction}>
          {/* {isAbleToPurchase ? "Purchase" : "View Availability"} */}
          Purchase
        </Button>

        {isAbleToPurchase ? (
          <Button buttonStyle="primary" onClick={purchaseAction}>
            Use Miles
          </Button>
        ) : (
          false
        )}
      </div>
      <SubText leftText="*" className="padding-hor-16">
        Availability is based on AirFly and may not be 100% accurate or fully
        correspond with the <b>{order.airline}</b> website.
      </SubText>
    </div>
  );
});

export default OrderReview;
