import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import Button from "components/Buttons/Button";
import InputText from "components/Inputs/InputText";
import DropDown from "components/DropDown";
import SlidDown from "../SlidDown";
import { flightClassOption, milesProgram, qntPassengerOption } from "./utils";
import ProfileIcon from "../../../assets/icons/avatars/profile-gray.svg";
import { useMilesPrograms } from "contexts/useMilesPrograms";

interface SlidDownFilterFlightsProps {
  className?: string;
  isOpen: boolean;
  closeSlidDownAreaCallback: (isOpen: boolean) => void;
  filterResultsCallback: (obj: SlidDownInfo) => void;
}

export type SlidDownInfo = {
  milesProgram: string | undefined;
  classSelected: string | undefined;
  dateSelected: string | undefined;
  stopsSelected: string | undefined;
  passengerQnt: string | undefined;
  maxMiles: string | undefined;
};

const SlidDownFilterFlights: React.FC<SlidDownFilterFlightsProps> = (props) => {
  const {
    isOpen,
    className,
    filterResultsCallback,
    closeSlidDownAreaCallback,
  } = props;

  const [isSlideDownVisible, setIsSlidDownVisible] = useState(false);
  const [dateSelected, setDateSelected] = useState("");
  const [stopsSelected, setStopsSelected] = useState<string[]>([]);
  const [classSelected, setClassSelected] = useState<string[]>([]);
  const [milesProgramState, setMilesProgramState] = useState<string[]>([]);
  const [passengerQnt, setPassengerQnt] = useState<string[]>([]);
  const [maxMiles, setMaxMiles] = useState("");

  const slidDownRef = useRef<HTMLDivElement | null>(null);
  const { milesOptions } = useMilesPrograms();

  useEffect(() => {
    setIsSlidDownVisible(isOpen);

    clearFields();
  }, [isOpen]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      slidDownRef.current &&
      !slidDownRef.current.contains(event.target as Node)
    ) {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const clearFields = () => {
    setDateSelected("");
    setClassSelected([]);

    setStopsSelected([]);
  };

  const filterFlightsHandler = () => {
    const prepareDataObj = {
      milesProgram: milesProgramState[0] ?? undefined,
      classSelected: classSelected[0] ?? undefined,
      dateSelected,

      stopsSelected: stopsSelected[0] ?? undefined,
      passengerQnt: passengerQnt[0] ?? undefined,
      maxMiles: maxMiles,
    };
    filterResultsCallback(prepareDataObj);
    closeModal();
  };

  const closeModal = () => {
    closeSlidDownAreaCallback(false);
    setIsSlidDownVisible(false);
    clearFields();
  };

  return (
    <div
      id="slid-down-filter-flights"
      ref={slidDownRef}
      className={className ?? ""}
    >
      <SlidDown
        classNameContainer="user-desk-slid-down"
        classNameChildren="list-item-slid-down"
        isOpen={isSlideDownVisible}
        closeArea={() => {
          setIsSlidDownVisible(false);
          closeSlidDownAreaCallback(false);
        }}
      >
        <p className="title">Date </p>
        <InputText
          inputType="date"
          value={dateSelected}
          onChangeInput={setDateSelected}
          placeholder="Select Date"
        />

        <DropDown
          label="Miles program"
          defaultValueProp={[milesProgram[0].inputValue]}
          setValueCallBack={setMilesProgramState}
          placeholder={"Any"}
          options={milesOptions}
          dropType="click-select"
          readOnly={true}
          className="margin-top-16"
        />

        <div className="grid-container margin-top-16">
          <p className="title no-margin">Flight Options</p>
          <div className="dropdowns margin-bottom-16">
            <DropDown
              setValueCallBack={setPassengerQnt}
              placeholder={"Any"}
              options={qntPassengerOption}
              defaultValueProp={[qntPassengerOption[0].inputValue]}
              dropType="click-select"
              readOnly={true}
              iconLeft={ProfileIcon}
            />
            <DropDown
              setValueCallBack={setClassSelected}
              placeholder={"Any"}
              defaultValueProp={[flightClassOption[0].inputValue]}
              options={flightClassOption}
              dropType="click-select"
              readOnly={true}
            />
          </div>
        </div>
        <InputText
          inputType="tel"
          placeholder="Max Miles"
          onChangeInput={setMaxMiles}
          value={maxMiles}
        />

        <Button onClick={filterFlightsHandler} buttonStyle="primary">
          Apply
        </Button>
        <Button buttonStyle="quintenary" onClick={clearFields}>
          Clear all
        </Button>
      </SlidDown>
    </div>
  );
};

export default SlidDownFilterFlights;
