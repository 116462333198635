import { FilterFlightObj } from "types/filter";
import { IOriginDestiny } from "types/originDestiny";

export const searchFilteredConsultCallback = (
  data: FilterFlightObj,
  setOriginDestiny: (data: IOriginDestiny) => void
) => {
  // y: "economic",
  // w: "premium",
  // j: "executive",
  // f: "first",
  const classSymbol: "Y" | "W" | "J" | "F" | undefined =
    data.classSelected === "economic"
      ? "Y"
      : data.classSelected === "premium"
      ? "W"
      : data.classSelected === "executive"
      ? "J"
      : data.classSelected === "first"
      ? "F"
      : undefined;

  const prepareParams = {
    origin: {
      iata: data.departureIATA,
      lat: data.departureCoord.lat,
      lon: data.departureCoord.lon,
    },
    destiny: {
      iata: data.destinationIATA,
      lat: data.destinationCoord.lat,
      lon: data.destinationCoord.lon,
      classes: classSymbol ? [classSymbol] : undefined,
      direct: data.stopsSelected === "Direct",
      date: data.dateSelected || undefined,
    },
  };
  setOriginDestiny(prepareParams);
};
