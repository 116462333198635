import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

interface Element {
  inputLabel: string;
  inputValue: string;
  groupName: string;
}

interface MilesProgramsResponse {
  source: string;
  mileageProgram: string;
}

interface UseMilesProgramsProps {
  milesOptions: Element[];
  setMilesOptionsState: (flight: MilesProgramsResponse[]) => void;
}

const UseMilesProgramsContext = createContext<UseMilesProgramsProps>(
  {} as UseMilesProgramsProps
);

const UseMilesProgramsProvider = ({ children }: { children: ReactNode }) => {
  const [milesOptionsState, setMilesOptionsState] = useState<
    MilesProgramsResponse[]
  >([]);
  const [milesOptions, setMilesOptions] = useState<Element[]>([]);

  const syncWithLocalStorage = () => {
    const elementData: Element[] = milesOptionsState.map((element) => ({
      groupName: "miles-programs",
      inputLabel: element.mileageProgram,
      inputValue: element.mileageProgram,
    }));

    localStorage.setItem("miles-programs", JSON.stringify(elementData));
    setMilesOptions(elementData);
  };

  useEffect(() => {
    const milesProgramsStorage = localStorage.getItem("miles-programs");

    if (milesProgramsStorage) {
      const milesStorage = JSON.parse(milesProgramsStorage);
      if (milesStorage.length) {
        setMilesOptions(milesStorage);
      } else {
        syncWithLocalStorage();
      }
    } else {
      syncWithLocalStorage();
    }
  }, [milesOptionsState]);

  return (
    <UseMilesProgramsContext.Provider
      value={{ milesOptions, setMilesOptionsState }}
    >
      {children}
    </UseMilesProgramsContext.Provider>
  );
};

const useMilesPrograms = () => {
  const context = useContext(UseMilesProgramsContext);

  if (!context) {
    throw new Error(
      "useMilesPrograms must be used within a UseMilesProgramsProvider"
    );
  }

  return context;
};

export { UseMilesProgramsProvider, useMilesPrograms };
