import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { getElementByPath } from "utils/navigationDesk";

interface Params {
  [key: string]: string;
}

interface DeskNavigationContextType {
  setPrincipalScreen: (path: string, params?: Params) => void;
  setSecondaryScreen: (path: string, params?: Params) => void;
  setTertiaryScreen: (path: string, params?: Params) => void;
  setMainScreen: (path: string, params?: Params) => void;
  componentPrincipal: ReactNode;
  componentSecondary: ReactNode;
  componentTertiary: ReactNode;
  mainScreen: ReactNode;
  params: Params;
}

const DeskNavigationContext = createContext({} as DeskNavigationContextType);

const DeskNavigationProvider = ({ children }: { children: ReactNode }) => {
  const [principalScreen, setPrincipalScreenState] = useState<string>("");
  const [componentPrincipal, setComponentPrincipal] =
    useState<ReactNode | null>(null);

  const [secondaryScreen, setSecondaryScreenState] = useState<string>("");
  const [componentSecondary, setComponentSecondary] =
    useState<ReactNode | null>(null);

  const [tertiaryScreen, setTertiaryScreenState] = useState<string>("");
  const [componentTertiary, setComponentTertiary] = useState<ReactNode | null>(
    null
  );

  const [mainScreen, setMainScreenState] = useState<string>("");
  const [componentMain, setComponentMain] = useState<ReactNode | null>(null);

  const [params, setParams] = useState<Params>({});

  const setScreen = (
    setState: React.Dispatch<React.SetStateAction<string>>,
    path: string,
    params?: Params
  ) => {
    if (params) {
      setParams((prev) => ({
        ...prev,
        ...params,
      }));
    }
    setState(path);
  };

  useEffect(() => {
    const page = getElementByPath(mainScreen);

    if (page) {
      setComponentMain(page);
    } else {
      setComponentMain(null);
    }
  }, [mainScreen]);

  useEffect(() => {
    const page = getElementByPath(principalScreen);

    if (page) {
      setComponentPrincipal(page);
    } else {
      setComponentPrincipal(null);
    }
  }, [principalScreen]);

  useEffect(() => {
    const page = getElementByPath(secondaryScreen);

    if (page) {
      setComponentSecondary(page);
    } else {
      setComponentSecondary(null);
    }
  }, [secondaryScreen]);

  useEffect(() => {
    const page = getElementByPath(tertiaryScreen);

    if (page) {
      setComponentTertiary(page);
    } else {
      setComponentTertiary(null);
    }
  }, [tertiaryScreen]);

  return (
    <DeskNavigationContext.Provider
      value={{
        componentPrincipal,
        componentSecondary,
        componentTertiary,
        setPrincipalScreen: (path, params) =>
          setScreen(setPrincipalScreenState, path, params),
        setSecondaryScreen: (path, params) =>
          setScreen(setSecondaryScreenState, path, params),
        setTertiaryScreen: (path, params) =>
          setScreen(setTertiaryScreenState, path, params),
        setMainScreen: (path, params) =>
          setScreen(setMainScreenState, path, params),
        mainScreen: componentMain,
        params,
      }}
    >
      {children}
    </DeskNavigationContext.Provider>
  );
};

const useDeskNavigation = () => {
  const context = useContext(DeskNavigationContext);
  return context;
};

export { DeskNavigationProvider, useDeskNavigation };
