import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

import HeaderNavigate from "components/Headers/HeaderNavigate";
import ListItemRanking from "components/Lists/ListItemRanking";
import QuizNavigation from "components/Headers/QuizNavigation";
import Loading from "components/Loading";

import { useUserInfo } from "contexts/userInfo";

import chevronLeftGray from "../../assets/icons/arrows/chevron-left-gray.svg";
import shared from "../../assets/icons/others/shared.svg";
import airplane from "../../assets/icons/airPlanes/airplane-orange.svg";

import { formatToTwoDigits } from "utils/formatToTwoDigits";
import { thousandsFormat } from "utils/thousandsFormat";
import { getInitials } from "utils/getInitials";
import getGameLeaderBoard from "api/game/getGameLeaderBoard";
import { useDeskNavigation } from "contexts/useDeskNavigation";
import PageTitle from "components/PageTitles";
import { useWindowWidthSize } from "contexts/useWindowWidthSize";

interface IRankingProp {
  isSelf?: boolean;
  position?: number;
  score?: number;
  user?: {
    country: string;
    displayName: string;
  };
  clickAction?: (a: any) => void;
}

const RankingPage: React.FC = () => {
  const { userInfo } = useUserInfo();
  const [topRanker, setTopRanker] = useState<IRankingProp | null>(null);
  const [remainingRanking, setRemainingRanking] = useState<IRankingProp[]>([]);
  const [loading, setLoading] = useState(true);
  const { setMainScreen, setPrincipalScreen } = useDeskNavigation();

  const { windowSize } = useWindowWidthSize();

  useEffect(() => {
    const fetchLeaderboard = async () => {
      const { topRanker, remainingRanking } = await getLeaderBoardHandler();
      setTopRanker(topRanker);
      setRemainingRanking(remainingRanking);
      setLoading(false);
    };

    fetchLeaderboard();
  }, []);

  const getLeaderBoardHandler = async () => {
    const request = await getGameLeaderBoard(
      userInfo["@air-fly_access-token"],
      "14"
    );

    const ranking = request.res.leaderboard;

    const topRanker = ranking.reduce(
      (max: IRankingProp, item: IRankingProp) => {
        return item.score && item.score > max.score! ? item : max;
      },
      ranking[0]
    );

    const remainingRanking = ranking.filter(
      (item: IRankingProp) => item !== topRanker
    );

    return { topRanker, remainingRanking };
  };

  const goBack = () => {
    setMainScreen("/airport-quiz");
    setPrincipalScreen("");
  };

  const inviteFriendsHandler = () => {
    // navigate("/");
  };

  const shareRankingHandler = () => {};

  return (
    <div className="ranking">
      <PageTitle
        rightButtonWhite={windowSize >= 1024}
        buttonWhite={windowSize <= 1024}
        className="padding-hor-24"
        shareIcon
        title="Airport QWZ"
        subtitle="Test your knowledge"
        shareIconAction={shareRankingHandler}
        onBackClick={goBack}
        backgroundColor={windowSize <= 1024 ? "chalk" : "white"}
      />

      <section
        className={`ranking-wrapper ${
          windowSize >= 1024 ? "backgroundWhite" : ""
        }`}
      >
        {loading ? (
          <div className="ranking-loading">
            <Loading />
          </div>
        ) : (
          <div className="ranking-box padding-16 margin-bottom-16">
            <div className="ranking-top">
              <img
                className="ranking-icon margin-bottom-8"
                src={airplane}
                alt=""
              />
              <h2 className="ranking-title no-margin">
                Airport<b>Qwz</b>
              </h2>
              <p className="ranking-desc no-margin">Leader Board</p>
            </div>

            <div className="margin-top-24">
              <ListItemRanking
                key={topRanker?.position}
                position={"1."}
                profileImage={getInitials(topRanker?.user?.displayName)}
                title={topRanker?.user?.displayName}
                desc={topRanker?.user?.country}
                secondaryText={thousandsFormat(Number(topRanker?.score))}
                hasSeparator={false}
                className="ranking-item ranking-item-alt padding-16"
              />
            </div>

            <hr className={`dotted-hr`} />

            <ul className="ranking-list padding-hor-16 no-padding">
              {remainingRanking.map((item, index, arr) => {
                return (
                  <li>
                    <ListItemRanking
                      key={item.position}
                      position={`${formatToTwoDigits(index + 2)}.`}
                      profileImage={getInitials(item.user?.displayName)}
                      title={item.user?.displayName}
                      desc={item.user?.country}
                      secondaryText={thousandsFormat(Number(item.score))}
                      hasSeparator={arr.length > index + 1}
                      className="ranking-item padding-right-8"
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </section>
    </div>
  );
};

export default RankingPage;
