import { ReactNode } from "react";
import { UserInfoProvider } from "./userInfo";
import { AirportsPinsProvider } from "./usePinsAirports";
import { LoginSignUpProvider } from "./useLoginSignUpNavigation";
import { DeskNavigationProvider } from "./useDeskNavigation";
import { SearchOriginDestinyProvider } from "./searchOriginDestiny";
import { ModalProvider } from "./useModal";
import { SearchedFlightProvider } from "./useSearchedFlight";
import { IconProvider } from "./useIconNav";
import { PwaProvider } from "hooks/pwa";
import { UseWindowWidthSizeProvider } from "./useWindowWidthSize";
import { ButtonDealProvider } from "./buttonDealContext";
import { UserNearbyAirportsProvider } from "hooks/useNearbyAirports";
import { UseMilesProgramsProvider } from "./useMilesPrograms";

const Contexts = ({ children }: { children: ReactNode }) => {
  return (
    <PwaProvider>
      <UseMilesProgramsProvider>
        <UserNearbyAirportsProvider>
          <UserInfoProvider>
            <ModalProvider>
              <SearchedFlightProvider>
                <AirportsPinsProvider>
                  <LoginSignUpProvider>
                    <SearchOriginDestinyProvider>
                      <IconProvider>
                        <UseWindowWidthSizeProvider>
                          <ButtonDealProvider>
                            <DeskNavigationProvider>
                              {children}
                            </DeskNavigationProvider>
                          </ButtonDealProvider>
                        </UseWindowWidthSizeProvider>
                      </IconProvider>
                    </SearchOriginDestinyProvider>
                  </LoginSignUpProvider>
                </AirportsPinsProvider>
              </SearchedFlightProvider>
            </ModalProvider>
          </UserInfoProvider>
        </UserNearbyAirportsProvider>
      </UseMilesProgramsProvider>
    </PwaProvider>
  );
};

export default Contexts;
