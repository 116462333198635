import React, { ReactNode, useContext, useEffect, useState } from "react";
import "./styles.scss";
import MapRoute, { ICoordinates } from "components/MapRoute";
import HeaderNavigate from "components/Headers/HeaderNavigate";
import { IOptionInputTextSelectProps } from "components/Inputs/InputTextSelect";
import getAirportsCodes from "api/flights/getAirportsCodes";
import { useUserInfo } from "contexts/userInfo";
import { IAirportsSearch } from "types/airportsSearch";
import { useSearchOriginDestiny } from "contexts/searchOriginDestiny";
import { AirportPin } from "types/airportsPins";
import getAirports from "api/airports/getAirports";
import SlidUpFilterFlights from "components/Modal/SlidUpFilterFlights";
import { useModal } from "contexts/useModal";
import ModalFilterFlights from "components/Modal/ModalFilterFlights";
import Button from "components/Buttons/Button";
import { useDeskNavigation } from "contexts/useDeskNavigation";
import { useWindowWidthSize } from "contexts/useWindowWidthSize";
import ToolBar, { ButtonsToolbar } from "components/ToolBar";
import { useIconContext } from "contexts/useIconNav";
import { ButtonDealContext } from "contexts/buttonDealContext";
import airplane from "../../../assets/icons/airPlanes/airplane-white.svg";
import SearchFlightFilterBar from "components/SearchFlightFilterBar";
import { FilterFlightObj } from "types/filter";
import { searchFilteredConsultCallback } from "utils/searchFilteredConsultCallback";

interface DeskProps {
  majorMain?: ReactNode;
  principalMain?: ReactNode;
  secondaryMain?: ReactNode;
  tertiaryMain?: ReactNode;
  clickProfileAvatar: (a: any) => void;
  profileAvatar: string;
  originCoordinates?: ICoordinates;
  destinyCoordinates?: ICoordinates;
  airportsPins: AirportPin[];
  isLinkLayout?: boolean;
  clickOnLogo?: () => void;
  onClickLogin?: (a: any) => void;
  listFavoriteFlights: (a?: any) => void;
}

const Desk: React.FC<DeskProps> = ({
  originCoordinates,
  destinyCoordinates,
  principalMain,
  secondaryMain,
  tertiaryMain,
  clickProfileAvatar,
  profileAvatar,
  airportsPins,
  majorMain,
  isLinkLayout,
  clickOnLogo,
  onClickLogin,
  listFavoriteFlights,
}) => {
  const [isPrincipalMainVisible, setIsPrincipalMainVisible] = useState(false);
  const [isSecondaryMainVisible, setIsSecondaryMainVisible] = useState(false);
  const [isTertiaryMainVisible, setIsTertiaryMainVisible] = useState(false);
  const [inputSearchResultOptions, setInputSearchResultOptions] = useState(
    {} as IOptionInputTextSelectProps[]
  );
  const { showButtonDeal } = useContext(ButtonDealContext);

  const { userInfo } = useUserInfo();
  const { setModalContent, setOpenUseModal } = useModal();
  const { setOriginDestiny } = useSearchOriginDestiny();
  const [showInputState, setShowInputState] = useState(true);
  const [isSlideUpVisible, setIsSlidUpVisible] = useState(false);
  const [airports, setAirports] = useState([]);
  const {
    setMainScreen,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
  } = useDeskNavigation();

  const { windowSize } = useWindowWidthSize();
  const { handleButtonClick, getIconClass } = useIconContext();
  const [showSearchbar, setShowSearchBar] = useState(false);

  useEffect(() => {
    setInputSearchResultOptions({} as IOptionInputTextSelectProps[]);
  }, [isSlideUpVisible]);

  useEffect(() => {
    if (principalMain) {
      setIsPrincipalMainVisible(true);
    } else {
      setIsPrincipalMainVisible(false);
    }
    changeInputSearchVisible(principalMain);
  }, [principalMain]);

  useEffect(() => {
    if (secondaryMain) {
      setIsSecondaryMainVisible(true);
    } else {
      setIsSecondaryMainVisible(false);
    }
    changeInputSearchVisible(secondaryMain);
  }, [secondaryMain]);

  useEffect(() => {
    if (tertiaryMain) {
      setIsTertiaryMainVisible(true);
    } else {
      setIsTertiaryMainVisible(false);
    }
    changeInputSearchVisible(tertiaryMain);
  }, [tertiaryMain]);

  const changeInputSearchVisible = (type: ReactNode) => {
    if (React.isValidElement(type) && typeof type.type !== "string") {
      changeStateSearchVisible(type.type.name);
    } else {
      return setShowInputState(true);
    }
  };

  const changeStateSearchVisible = async (type: string) => {
    if (isLinkLayout) return;

    if (type.includes("UserSettings")) {
      return setShowInputState(false);
    }

    try {
      const request = await getAirports(userInfo["@air-fly_access-token"]);
      if (request.res.data) {
        setAirports(request.res.data);
      }
      return setShowInputState(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (value: string) => {
    setOriginDestiny({
      destiny: { iata: value, lat: 0, lon: 0 },
      origin: { iata: originCoordinates?.iataCode || "", lat: 0, lon: 0 },
    });
  };

  const handleInputChange = async (search: string, category?: string) => {
    if (
      userInfo["@air-fly_user-plan"] === "global" ||
      userInfo["@air-fly_user-plan"] === "travel"
    ) {
      try {
        const request = await getAirportsCodes(
          userInfo["@air-fly_access-token"],
          {
            search,
          }
        );

        setInputSearchResultOptions(
          request.res.data.map((airport: IAirportsSearch) => {
            return {
              label: `${airport.iata} - ${airport.name}`,
              detailsText: `${airport.city}, ${airport.country}`,
              value: JSON.stringify(airport),
            } as IOptionInputTextSelectProps;
          })
        );
      } catch (error) {
        console.error(error);
      }
    } else {
      setModalContent(
        <section className="payment-response">
          <span className="icon icon-airplane icon-md icon-orange "></span>
          <h5 className="no-margin margin-top-16">
            <b>Oops, you need to upgrade your plan!</b>
          </h5>
          <p className="small-p margin-bottom-16">
            Unlock all filters with our premium plans!
          </p>
          <div className="buttons-container">
            <Button
              children={"Upgrade Now"}
              buttonStyle={"primary"}
              onClick={() => {
                handleTryAgain();
              }}
            />
            <Button
              children={"Continue Free"}
              buttonStyle={"tertiary"}
              onClick={() => {
                setOpenUseModal(false);
              }}
            />
          </div>
        </section>
      );
      setOpenUseModal(true);
    }
  };

  const handleTryAgain = () => {
    setOpenUseModal(false);
    setMainScreen("");
    setPrincipalScreen("/user-settings-subscriptions");
    setSecondaryScreen("");
    setTertiaryScreen("");
  };

  const clickFilterIcon = () => {
    // if (userInfo["@air-fly_user-plan"] === "global") {
    // if (windowSize <= 768) {
    //   setIsSlidUpVisible((prev) => !prev);
    // } else {
    //   setModalContent(
    //     <ModalFilterFlights
    //       inputSearchResultOptions={inputSearchResultOptions}
    //       titleHead="Add Filter"
    //       titleText="Destination"
    //       primaryButtonText="Add Filter"
    //       filterResultsCallback={(data) =>
    //         searchFilteredConsultCallback(data, setOriginDestiny)
    //       }
    //     />
    //   );
    //   setTimeout(() => {
    //     setOpenUseModal(true);
    //   }, 0);
    // }
    // } else {
    //   setModalContent(
    //     <section className="payment-response">
    //       <span className="icon icon-airplane icon-md icon-orange "></span>
    //       <h5 className="no-margin margin-top-16">
    //         <b>Oops, you need to upgrade your plan!</b>
    //       </h5>
    //       <p className="small-p margin-bottom-16">
    //         Unlock all filters with our premium plans!
    //       </p>

    //       <div className="buttons-container">
    //         <Button
    //           children={"Upgrade Now"}
    //           buttonStyle={"primary"}
    //           onClick={() => {
    //             handleTryAgain();
    //           }}
    //         />
    //         <Button
    //           children={"Continue Free"}
    //           buttonStyle={"tertiary"}
    //           onClick={() => {
    //             setOpenUseModal(false);
    //           }}
    //         />
    //       </div>
    //     </section>
    //   );
    //   setOpenUseModal(true);
    // }

    setShowSearchBar((prev) => !prev);
  };

  const airportQuizHandler = () => {
    setMainScreen("/airport-quiz");
    setPrincipalScreen("");
    setSecondaryScreen("");
    setTertiaryScreen("");
  };

  const listFlights = () => {
    setMainScreen("");
    setSecondaryScreen("");
    setTertiaryScreen("");
    setPrincipalScreen("/search-milesy");
  };

  const alertHandler = () => {
    setMainScreen("");
    setPrincipalScreen("/my-alerts");
    setSecondaryScreen("");
    setTertiaryScreen("");
  };

  const buttonToolbar: ButtonsToolbar[] = [
    {
      action: () => {
        handleButtonClick("grid", listFlights);
        setIsSlidUpVisible(false);
      },
      icon: "icon-grid",
      text: "",
      className: getIconClass("grid"),
    },
    {
      action: () => {
        handleButtonClick("heart-outline", listFavoriteFlights);
        setIsSlidUpVisible(false);
      },
      icon: "icon-heart-outline",
      text: "",
      className: getIconClass("heart-outline"),
    },
    {
      action: () => {
        handleButtonClick("notification", alertHandler);
        setIsSlidUpVisible(false);
      },
      icon: "icon-notification",
      text: "",
      className: getIconClass("notification"),
    },
    {
      action: () => {
        handleButtonClick("flag", airportQuizHandler);
        setIsSlidUpVisible(false);
      },
      icon: "icon-flag",
      text: "",
      className: getIconClass("flag"),
    },
  ];

  return (
    <div id="desk-wrapper">
      <section className="desk-wrapper__header-wrapper">
        <HeaderNavigate
          isLinkLayout={isLinkLayout}
          userAvatarImage={profileAvatar}
          className="padding-hor-24"
          clickAvatarCallback={clickProfileAvatar}
          showLogo={true}
          clickOnLogo={clickOnLogo}
          onClickLogin={onClickLogin}
        />
      </section>
      <main
        className={`main-page-desk ${
          isPrincipalMainVisible || isSecondaryMainVisible
            ? `space-without-header ${
                isTertiaryMainVisible ? "grid-template-changed" : ""
              }`
            : ""
        }`}
      >
        <div
          className={`map-main ${
            isSecondaryMainVisible &&
            isTertiaryMainVisible &&
            isPrincipalMainVisible
              ? "hide-map-for-tertiary-main"
              : isPrincipalMainVisible && !isSecondaryMainVisible
              ? "hide-map"
              : isSecondaryMainVisible && isPrincipalMainVisible
              ? "secondary-hide-map"
              : isSecondaryMainVisible && !isPrincipalMainVisible
              ? "only-secondary-main-is-show"
              : "show-map"
          }`}
        >
          {majorMain ? (
            majorMain
          ) : (
            <div className="map-route-wrapper">
              {!isLinkLayout && showSearchbar ? (
                <SearchFlightFilterBar />
              ) : (
                false
              )}

              <MapRoute
                originCoordinates={originCoordinates}
                destinyCoordinates={destinyCoordinates}
                airportsPinsList={airportsPins}
              />
              {windowSize > 768 && !isLinkLayout ? (
                <ToolBar
                  buttons={buttonToolbar}
                  clickFilterIcon={clickFilterIcon}
                />
              ) : (
                false
              )}
            </div>
          )}
        </div>

        <div
          className={`principal-main ${
            isPrincipalMainVisible && !isSecondaryMainVisible
              ? "show-principal-main"
              : isPrincipalMainVisible &&
                isSecondaryMainVisible &&
                !isTertiaryMainVisible
              ? "show-principal-main show-principal-and-secondary-main"
              : isPrincipalMainVisible &&
                isSecondaryMainVisible &&
                isTertiaryMainVisible
              ? "show-principal-main hide-principal-main-mobile"
              : "hide-principal-main"
          }`}
        >
          {principalMain}
          <SlidUpFilterFlights
            closeSlidUpAreaCallback={setIsSlidUpVisible}
            isOpen={isSlideUpVisible}
            onChangeInput={handleInputChange}
            filterResultsCallback={(data) =>
              searchFilteredConsultCallback(data, setOriginDestiny)
            }
            titleHead="Add Filter"
            titleText="Destination"
            primaryButtonText="Add Filter"
          />
        </div>
        <div
          className={`secondary-main ${
            isSecondaryMainVisible && !isTertiaryMainVisible
              ? "show-secondary-main"
              : isSecondaryMainVisible &&
                isTertiaryMainVisible &&
                isPrincipalMainVisible
              ? "show-secondary-main show-secondary-and-tertiary-main"
              : isSecondaryMainVisible &&
                isTertiaryMainVisible &&
                !isPrincipalMainVisible
              ? "show-secondary-main only-secondary-and-tertiary-main-show"
              : "hide-secondary-main"
          }`}
        >
          {secondaryMain}
        </div>

        <div
          className={`tertiary-main ${
            isTertiaryMainVisible &&
            isSecondaryMainVisible &&
            isPrincipalMainVisible
              ? "show-tertiary-main"
              : !isPrincipalMainVisible &&
                isSecondaryMainVisible &&
                isTertiaryMainVisible
              ? "show-all-main"
              : "hide-tertiary-main"
          }`}
        >
          {tertiaryMain}
        </div>
      </main>
      {showButtonDeal && windowSize < 768 ? (
        <div className="button-deal-wrapper">
          <Button buttonStyle="primary" onClick={clickFilterIcon}>
            <img src={airplane} alt="airplane" />
            Find Deals
          </Button>
        </div>
      ) : (
        false
      )}

      {windowSize < 768 && !isLinkLayout ? (
        <ToolBar buttons={buttonToolbar} clickFilterIcon={clickFilterIcon} />
      ) : (
        false
      )}
    </div>
  );
};

export default Desk;
