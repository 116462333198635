import React, { useEffect, useState } from "react";
import "./styles.scss";
import { FlightAvailability } from "types/flights";
import { ClassesFlightsType } from "types/flightDetails";

interface FlightClassAvailableProps {
  flight?: FlightAvailability;
  seatsAvailableList?: string[];
  className?: string;
  isUniqueClass?: boolean;
  allClassesSelected?: boolean;
  dotPalette?: "orange" | "white";
}

const FlightClassAvailable: React.FC<FlightClassAvailableProps> = (props) => {
  const {
    flight,
    seatsAvailableList,
    className,
    isUniqueClass,
    allClassesSelected,
    dotPalette = "orange",
  } = props;

  const [seatsList, setSeatList] = useState<string[]>([]);

  useEffect(() => {
    if (flight) {
      const seatType = [
        flight.YAvailable ? "economic" : null,
        flight.WAvailable ? "premium" : null,
        flight.JAvailable ? "executive" : null,
        flight.FAvailable ? "first" : null,
      ].filter(Boolean) as ClassesFlightsType[];
      if (isUniqueClass) {
        setSeatList([seatType[0]]);
      } else {
        setSeatList(seatType);
      }
    } else if (seatsAvailableList?.length) {
      setSeatList(seatsAvailableList);
    }
  }, []);

  return (
    <div className={`no-margin ticket-seat-type ${className ?? ""}`}>
      <div className="dots">
        <span
          className={`dot-ticket ${
            seatsList.includes("economic") || allClassesSelected
              ? "highlight"
              : ""
          } palette-${dotPalette}`}
        ></span>
        <span
          className={`dot-ticket ${
            seatsList.includes("premium") || allClassesSelected
              ? "highlight"
              : ""
          } palette-${dotPalette}`}
        ></span>
        <span
          className={`dot-ticket ${
            seatsList.includes("executive") || allClassesSelected
              ? "highlight"
              : ""
          } palette-${dotPalette}`}
        ></span>
        <span
          className={`dot-ticket ${
            seatsList.includes("first") || allClassesSelected ? "highlight" : ""
          } palette-${dotPalette}`}
        ></span>
      </div>
    </div>
  );
};

export default FlightClassAvailable;
