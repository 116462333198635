import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import AirFlyLogo from "../../../assets/logos/air-fly-logo-1.svg";
import leftArrowGray from "../../../assets/icons/arrows/chevron-left-gray.svg";
import Button from "components/Buttons/Button";

interface HeaderNavigateProps {
  className?: string;
  navigateText?: string;
  navigateTo?: string;
  clickAvatarCallback?: (a: any) => void;
  userAvatarImage: string;
  showLogo?: boolean;
  isLinkLayout?: boolean;
  clickOnLogo?: (a: any) => void;
  onClickLogin?: (a: any) => void;
}

const HeaderNavigate: React.FC<HeaderNavigateProps> = (props) => {
  const {
    className,
    navigateText,
    navigateTo,
    clickAvatarCallback,
    userAvatarImage,
    showLogo,
    isLinkLayout,
    clickOnLogo,
    onClickLogin,
  } = props;

  const navigate = useNavigate();

  return (
    <header id="header-navigate-default" className={className ?? ""}>
      {showLogo && (
        <div className="header-wrapper">
          <div
            className="icon-container airFly-logo-text"
            onClick={clickOnLogo}
          >
            <img src={AirFlyLogo} alt="airFly logo" />
          </div>
          {/* {inputButtonsRenderer("wide")} */}
          <div className="profile-user-container">
            {isLinkLayout ? (
              <Button onClick={onClickLogin} buttonStyle="primary">
                Login
              </Button>
            ) : (
              <img
                src={userAvatarImage}
                alt="user avatar"
                onClick={clickAvatarCallback}
              />
            )}
          </div>
        </div>
      )}

      {navigateTo && (
        <div
          className="button-navigate-page margin-bottom-16"
          onClick={() => navigate(navigateTo)}
        >
          <img src={leftArrowGray} alt="left arrow" />
          <p>{navigateText}</p>
        </div>
      )}

      {/* {inputButtonsRenderer("mobile")} */}
    </header>
  );
};

export default HeaderNavigate;
