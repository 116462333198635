import React, { ReactNode } from "react";
import "./styles.scss";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  className?: string;
  color?: "orange";
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  buttonStyle:
    | "primary"
    | "secondary"
    | "tertiary"
    | "quaternary"
    | "quintenary"
    | "icon";
}

const Button: React.FC<ButtonProps> = (props) => {
  const { buttonStyle, children, className, onClick, color, ...rest } = props;

  return (
    <button
      id="button"
      onClick={onClick}
      className={`${buttonStyle} ${color} ${
        buttonStyle === "quintenary" ? " background-blur-gray " : ""
      } ${className ?? ""}`}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
