import React, { useEffect, useState } from "react";
import "./styles.scss";
import LogInSignUpDesk from "components/Wrappers/LoginSignUpDesk";
import { useLoginSignUpNavigation } from "contexts/useLoginSignUpNavigation";
import InputText from "components/Inputs/InputText";
import Button from "components/Buttons/Button";
import { IToast } from "types/toast";
import Toast from "components/Toast";
import Header from "components/Headers/Header";
import SubText from "components/Texts/SubText";
import SplashPage from "pages/Splash";
import postWaitList, { PostWaitListBody } from "api/waitList/postWaitList";
import postVerifyEmail from "api/waitList/postVerifyEmail";
import CheckCircleGreen from "../../assets/icons/checks/check-circle-green.svg";
import ErrorCheck from "../../assets/icons/close/close-icon-pink.svg";
import Loading from "components/Loading";
import { usePwa } from "../../hooks/pwa";
import { useNavigate } from "react-router-dom";
import { useWindowWidthSize } from "contexts/useWindowWidthSize";

const LandingPage: React.FC = () => {
  const { isPwa } = usePwa();
  const navigate = useNavigate();
  const { component, setComponent } = useLoginSignUpNavigation();
  const [isLoading, setIsLoading] = useState(false);
  const { windowSize } = useWindowWidthSize();

  const [isSplashPage, setIsSplashPage] = useState(true);
  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });
  const [isInitialPage, setIsInitialPage] = useState(false);

  useEffect(() => {
    if (isPwa) {
      navigate("/home");
    }
  }, [isPwa]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const token = params.get("token");

    if (token) {
      confirmEmailVerify(token);
    }
  }, [window.location.search]);

  useEffect(() => {
    if (isInitialPage) {
      setComponent(<NotifyAirFlyAvailable />);
    }
  }, [isInitialPage]);

  const confirmEmailVerify = async (token: string) => {
    setIsLoading(true);
    try {
      const response = await postVerifyEmail({ token });

      switch (response.status) {
        case 201:
        case 200:
          setComponent(pagesVerificationsContent("success"));

          break;

        case 400:
          if (response.res.message.includes("400006")) {
            setComponent(pagesVerificationsContent("email-already-verified"));
          } else {
            setComponent(pagesVerificationsContent("error"));
          }
          break;
        default:
          setToastConfig({
            description:
              "We had a problem making the request. Please try again",
            title: "Sorry",
            type: "error",
          });

          setToastShow(true);
          setTimeout(() => {
            setToastShow(false);
          }, 4000);

          break;
      }
    } catch (error) {
      console.error(error);

      setToastConfig({
        description: "We had a problem making the request. Please try again",
        title: "Sorry",
        type: "error",
      });

      setToastShow(true);
      setTimeout(() => {
        setToastShow(false);
      }, 4000);
    } finally {
      setIsLoading(false);
    }
  };

  const backButtonClick = () => {
    const params = new URLSearchParams(window.location.search);
    params.delete("token");
    const url = new URL(window.location.href);
    url.search = params.toString();
    window.history.replaceState(null, "", url);

    setIsInitialPage(true);
  };

  const pagesVerificationsContent = (
    status: "error" | "success" | "email-already-verified"
  ) => {
    if (status === "success") {
      return (
        <section className="email-verify-content-modal">
          <img src={CheckCircleGreen} alt="check" className="status-request" />

          <h5 className="no-margin email-verify-content-modal__title">
            Your email has been successfully verified!
          </h5>
          <p className="small-p email-verify-content-modal__explanation-text">
            You will receive news from AirFly in your registered email
          </p>

          <Button onClick={backButtonClick} buttonStyle="primary">
            Close
          </Button>
        </section>
      );
    } else if (status === "error") {
      return (
        <section className="email-verify-content-modal">
          <img src={ErrorCheck} alt="error check" className="status-request" />

          <h5 className="no-margin email-verify-content-modal__title">
            Unable to verify your email.
          </h5>
          <p className="small-p email-verify-content-modal__explanation-text">
            Please, check your credential and try again.
          </p>

          <Button onClick={backButtonClick} buttonStyle="primary">
            Close
          </Button>
        </section>
      );
    } else if (status === "email-already-verified") {
      return (
        <section className="email-verify-content-modal">
          <h5 className="no-margin email-verify-content-modal__title">
            This email has already been verified
          </h5>
          <p className="small-p email-verify-content-modal__explanation-text">
            When we have news about AirFly, you will be informed as soon as
            possible via the email provided
          </p>

          <Button onClick={backButtonClick} buttonStyle="primary">
            Close
          </Button>
        </section>
      );
    }
  };

  if (isLoading) {
    setComponent(<Loading />);
  }

  return (
    <section className="landing-page">
      {isSplashPage && windowSize < 768 ? (
        <SplashPage
          backgroundColor="var(--orange)"
          finishLoadingTrigger={() => setIsSplashPage(false)}
        />
      ) : (
        <LogInSignUpDesk
          isLandingPage
          component={component || <NotifyAirFlyAvailable />}
        />
      )}
      <Toast
        type={toastConfig.type}
        isVisible={toastShow}
        setIsVisible={setToastShow}
        title={toastConfig.title}
        description={toastConfig.description}
      />
    </section>
  );
};

const NotifyAirFlyAvailable: React.FC = () => {
  const { component, setComponent } = useLoginSignUpNavigation();
  const [emailInputWarning, setEmailInputWarning] = useState(false);
  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [givenConsent, setGivenConsent] = useState(false);
  const [givenConsentCheckWarning, setGivenConsentCheckWarning] =
    useState(false);

  const emailSendedSuccessfully = (
    <section className="email-verify-content-modal">
      <img src={CheckCircleGreen} alt="check" className="status-request" />

      <h5 className="no-margin email-verify-content-modal__title">
        Verification email sent successfully
      </h5>
      <p className="small-p email-verify-content-modal__explanation-text">
        Check your email inbox and click on the link we sent you. Also check
        your spam folder.
      </p>
    </section>
  );

  const sendEmailHandler = async () => {
    setIsLoading(true);
    if (!userEmail) {
      setToastShow(true);
      setEmailInputWarning(true);

      setToastConfig({
        description: "You need to add your email to continue",
        title: "Email is empty",
        type: "info",
      });

      setTimeout(() => {
        setEmailInputWarning(false);
        setToastShow(false);
      }, 4000);
      setIsLoading(false);

      return;
    }

    if (
      userEmail.length < 4 ||
      !userEmail.includes("@") ||
      !userEmail.includes(".com") ||
      !userEmail.split("@")[0].length
    ) {
      setToastShow(true);
      setEmailInputWarning(true);

      setToastConfig({
        description: "Please, add a valid email.",
        title: "Invalid Email",
        type: "info",
      });

      setTimeout(() => {
        setEmailInputWarning(false);
        setToastShow(false);
      }, 4000);
      setIsLoading(false);

      return;
    }

    if (!givenConsent) {
      setToastShow(true);
      setGivenConsentCheckWarning(true);

      setToastConfig({
        description:
          "You must consent to the email being sent before we can send you",
        title: "permission not granted",
        type: "info",
      });

      setTimeout(() => {
        setGivenConsentCheckWarning(false);
        setToastShow(false);
      }, 4000);
      setIsLoading(false);

      return;
    }

    const prepareData: PostWaitListBody = {
      consentGiven: givenConsent,
      email: userEmail,
      // newsLetter: newsLetter,
    };

    try {
      const request = await postWaitList(prepareData);

      switch (request.status) {
        case 201:
          setComponent(emailSendedSuccessfully);
          setGivenConsent(false);
          setUserEmail("");

          setTimeout(() => {
            setComponent(<NotifyAirFlyAvailable />);
          }, 6000);
          break;
        case 409:
          setToastConfig({
            description:
              "Your email has already been registered on our platform.",
            title: "Email already registered",
            type: "info",
          });
          setToastShow(true);
          setUserEmail("");

          setTimeout(() => {
            setToastShow(false);
          }, 5000);
          break;

        default:
          if (request.res.message.includes("400009")) {
            setToastConfig({
              description:
                "Your email has already been registered on our platform.",
              title: "Email already registered",
              type: "info",
            });
            setToastShow(true);
            setUserEmail("");

            setTimeout(() => {
              setToastShow(false);
            }, 5000);
            break;
          }
          setToastConfig({
            description:
              "We had a problem making the request. Please try again",
            title: "Sorry",
            type: "error",
          });
          setToastShow(true);

          setTimeout(() => {
            setToastShow(false);
          }, 4000);
          break;
      }
    } catch (error) {
      console.error(error);
      setToastShow(true);

      setToastConfig({
        description: "We had a problem making the request. Please try again",
        title: "Sorry",
        type: "error",
      });

      setTimeout(() => {
        setToastShow(false);
      }, 4000);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="notify-air-fly-available margin-top-24">
      <Header
        headerIcon="airFly"
        title="Buzzing thru the skies"
        text="Be the First to Take Off with AirFly."
        titleClass="header"
        subTitle={`Something exciting is about to take flight! Enter your email
  				 below, and we’ll notify you the moment our app goes live.`}
      />
      <section className="notify-air-fly-available-main">
        <InputText
          value={userEmail.toLowerCase()}
          inputType="email"
          placeholder="Email"
          textTransform="none"
          onChangeInput={(e) => setUserEmail(e)}
          isWarning={emailInputWarning}
          className="no-margin"
        />
        <SubText
          checkAnswer
          checkValueCallback={(evt) => {
            setGivenConsent(evt);
          }}
          isCheckBoxWarning={givenConsentCheckWarning}
          isChecked={givenConsent}
          className="notify-air-fly-available-main__subtext"
        >
          I want to receive news, updates, and offers from AirFly.
        </SubText>

        <Button
          disabled={isLoading}
          buttonStyle="primary"
          onClick={sendEmailHandler}
        >
          Notify Me
        </Button>
      </section>

      <Toast
        type={toastConfig.type}
        isVisible={toastShow}
        setIsVisible={setToastShow}
        title={toastConfig.title}
        description={toastConfig.description}
      />
    </div>
  );
};

export default LandingPage;
