export const qntPassengerOption = [
  {
    groupName: "qnt-pass",
    inputLabel: `1`,
    inputValue: `1`,
  },
  {
    groupName: "qnt-pass",
    inputLabel: `2`,
    inputValue: `2`,
  },
  {
    groupName: "qnt-pass",
    inputLabel: `4`,
    inputValue: `4`,
  },
  {
    groupName: "qnt-pass",
    inputLabel: `6`,
    inputValue: `6`,
  },
];

export const flightClassOption = [
  {
    groupName: "class",
    inputLabel: `Economic`,
    inputValue: `Economic`,
  },
  {
    groupName: "class",
    inputLabel: `Premium`,
    inputValue: `Premium`,
  },
  {
    groupName: "class",
    inputLabel: `Executive`,
    inputValue: `Executive`,
  },
  {
    groupName: "class",
    inputLabel: `First Class`,
    inputValue: `First Class`,
  },
];

export const milesProgram = [
  {
    groupName: "milesProgram",
    inputLabel: `Smiles`,
    inputValue: `Smiles`,
  },
  {
    groupName: "milesProgram",
    inputLabel: `AAdvantage`,
    inputValue: `AAdvantage`,
  },
  {
    groupName: "milesProgram",
    inputLabel: `Miles & More`,
    inputValue: `Miles & More`,
  },

  {
    groupName: "milesProgram",
    inputLabel: `SkyMiles`,
    inputValue: `SkyMiles`,
  },
  {
    groupName: "milesProgram",
    inputLabel: `Executive Club`,
    inputValue: `Executive Club`,
  },
];
