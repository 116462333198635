import React, { useEffect, useState } from "react";
import "./styles.scss";
import Desk from "components/Wrappers/Desk";
import { useDeskNavigation } from "contexts/useDeskNavigation";
import { useUserInfo } from "contexts/userInfo";
import { useSearchOriginDestiny } from "contexts/searchOriginDestiny";
import { IAirportsSearch } from "types/airportsSearch";
import { AirportPin } from "types/airportsPins";
import { usePinsAirports } from "contexts/usePinsAirports";
import { useParams } from "react-router-dom";
import { useModal } from "contexts/useModal";
import Button from "components/Buttons/Button";
import getPaymentStatus from "api/plans/getPaymentStatus";
import getCurrentPlan from "api/plans/getCurrentPlan";
import { useUserNearbyAirports } from "hooks/useNearbyAirports";

const HomePage: React.FC = () => {
  const [profileAvatar, setProfileAvatar] = useState("");
  const { setModalContent, setOpenUseModal } = useModal();
  const { airportsPins } = usePinsAirports();
  const { originDestiny } = useSearchOriginDestiny();
  const { nearbyAirports } = useUserNearbyAirports();
  const { flight_id } = useParams();

  const {
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    mainScreen,
    setMainScreen,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
  } = useDeskNavigation();
  const { userInfo, setUserInfo } = useUserInfo();
  const [userCoordinates, setUserCoordinates] = useState({
    origin: {
      iataCode: "",
      lat: 0,
      long: 0,
    },
    destination: {
      iataCode: "",
      lat: 0,
      long: 0,
    },
  });
  const [airportsPinsState, setAirportsPinsState] = useState<AirportPin[]>([]);
  const [showBestOffers, setShowBestOffers] = useState(true);

  useEffect(() => {
    const captureUTMParams = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const allParams: Record<string, string> = {};

      urlParams.forEach((value, key) => {
        allParams[key] = value;
      });

      if (allParams.paymentStatus === "processing") {
        handlePaymentStatus();
        setModalContent(
          <section className="payment-response">
            {/* <span className="icon icon-check icon-sm icon-white icon-round round-sm success"></span> */}
            <span className="icon icon-info icon-sm icon-warning icon-round round-sm"></span>
            <h5 className="no-margin margin-top-16">
              <b>Processing your payment!</b>
            </h5>
            <p className="small-p margin-bottom-16">Wait while we check...</p>
            {/* <Button
              children={"Start Exploring"}
              buttonStyle={"tertiary"}
              onClick={() => {
                setOpenUseModal(false);
              }}
            /> */}
          </section>
        );
        setOpenUseModal(true);
      }
      if (allParams.paymentStatus === "unpaid") {
        setModalContent(
          <section className="payment-response">
            <span className="icon icon-close icon-sm icon-white icon-round round-sm fail"></span>
            <h5 className="no-margin margin-top-16">
              <b>Payment Canceled</b>
            </h5>
            <p className="small-p margin-bottom-16">
              It looks like you canceled your payment. If this was
              unintentional, you can try completing it again.
            </p>
            <div className="buttons-container">
              <Button
                children={"Try Again"}
                buttonStyle={"primary"}
                onClick={() => {
                  handleTryAgain();
                }}
              />
              <Button
                children={"Cancel"}
                buttonStyle={"tertiary"}
                onClick={() => {
                  setOpenUseModal(false);
                }}
              />
            </div>
          </section>
        );
        setOpenUseModal(true);
      } else {
        return;
      }
      // if (Object.keys(allParams).length > 0) {
      //   localStorage.setItem("urlParams", JSON.stringify(allParams));
      // }
    };

    const cleanURL = () => {
      if (window.location.search) {
        const url = new URL(window.location.href);
        url.search = "";
        window.history.replaceState({}, document.title, url.toString());
      }
    };

    captureUTMParams();
    cleanURL();
  }, []);

  useEffect(() => {
    if (componentPrincipal === null) {
      setPrincipalScreen("/search-milesy");
    }

    setProfileAvatar(userInfo["@air-fly_user_avatar"]);

    getCoordinatesAirports();
  }, [originDestiny]);

  useEffect(() => {
    setAirportsPinsState(airportsPins);
  }, [airportsPins]);

  useEffect(() => {
    setShowBestOffers(false);
  }, []);

  useEffect(() => {
    if (flight_id) {
      setShowBestOffers(false);

      setMainScreen("/best-offers-catalog");
      setPrincipalScreen(`/flight-details`, {
        flightId: flight_id,
      });
    }
  }, [flight_id]);

  const handlePaymentStatus = async () => {
    try {
      const response = await getPaymentStatus(
        userInfo["@air-fly_access-token"]
      );

      if (response.status === 200 && response.res) {
        const { paymentStatus } = response.res;

        if (paymentStatus === "succeeded" || paymentStatus === "paid") {
          setModalContent(
            <section className="payment-response">
              <span className="icon icon-check icon-sm icon-white icon-round round-sm success"></span>
              <h5 className="no-margin margin-top-16">
                <b>Subscribed with Success!</b>
              </h5>
              <p className="small-p margin-bottom-16">
                Start exploring the best of your plan!
              </p>
              <Button
                children={"Start Exploring"}
                buttonStyle={"tertiary"}
                onClick={() => {
                  setOpenUseModal(false);
                }}
              />
            </section>
          );
          setOpenUseModal(true);
          getUserPlan();
        }
        if (
          paymentStatus === "canceled" ||
          paymentStatus === "requires_payment_method" ||
          paymentStatus === "unpaid"
        ) {
          setModalContent(
            <section className="payment-response">
              <span className="icon icon-close icon-sm icon-white icon-round round-sm fail"></span>
              <h5 className="no-margin margin-top-16">
                <b>Oops, Subscription Failed!</b>
              </h5>
              <p className="small-p margin-bottom-16">
                Sorry, something went wrong.{" "}
              </p>
              <div className="buttons-container">
                <Button
                  children={"Try Again"}
                  buttonStyle={"primary"}
                  onClick={() => {
                    handleTryAgain();
                  }}
                />
                <Button
                  children={"Cancel"}
                  buttonStyle={"tertiary"}
                  onClick={() => {
                    setOpenUseModal(false);
                  }}
                />
              </div>
            </section>
          );
          setOpenUseModal(true);
        }
        if (
          paymentStatus === "processing" ||
          paymentStatus === "requires_action" ||
          paymentStatus === "requires_confirmation"
        ) {
          setTimeout(() => {
            handlePaymentStatus();
          }, 30000);
        }
      } else {
        console.error("Error fetching payment status:", response);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getUserPlan = async () => {
    try {
      const request = await getCurrentPlan(userInfo["@air-fly_access-token"]);
      setUserInfo("@air-fly_user-plan", request?.res?.plan?.name);
    } catch (error) {
      console.error(error);
    }
  };

  const getCoordinatesAirports = async () => {
    const airportsNearby: IAirportsSearch[] = nearbyAirports;

    let originCoords = {
      lat: airportsNearby[0].latitude,
      lon: airportsNearby[0].longitude,
      iataCode: airportsNearby[0].iata,
    };

    if (originDestiny.origin.lat && originDestiny.origin.lon) {
      originCoords = {
        iataCode: originDestiny.origin.iata || "",
        lat: originDestiny.origin.lat,
        lon: originDestiny.origin.lon,
      };
    }
    setUserCoordinates({
      origin: {
        iataCode: originCoords.iataCode,
        lat: originCoords.lat,
        long: originCoords.lon,
      },
      destination: {
        iataCode: originDestiny.destiny.iata || "",
        lat: originDestiny.destiny.lat,
        long: originDestiny.destiny.lon,
      },
    });
  };

  const clickProfileAvatar = () => {
    setShowBestOffers(false);
    setMainScreen("");
    setSecondaryScreen("");
    setTertiaryScreen("");
    setPrincipalScreen("/user-settings");
  };

  const handleTryAgain = () => {
    setOpenUseModal(false);
    setMainScreen("");
    setPrincipalScreen("/user-settings-subscriptions");
    setSecondaryScreen("");
    setTertiaryScreen("");
  };

  const originCoordinates = {
    iataCode: userCoordinates.origin.iataCode,
    lat: userCoordinates.origin.lat,
    long: userCoordinates.origin.long,
  };
  const destinyCoordinates = {
    iataCode: userCoordinates.destination.iataCode,
    lat: userCoordinates.destination.lat,
    long: userCoordinates.destination.long,
  };

  const listFavoriteFlights = () => {
    setOpenUseModal(false);
    setMainScreen("");
    setSecondaryScreen("");
    setTertiaryScreen("");
    setPrincipalScreen("/favorites-flies");
  };

  return (
    <>
      {/* {showBestOffers || activeIcon === "grid" ? (
        <>
          <section className="desk-wrapper__header-wrapper">
            <HeaderNavigate
              clickOnFlag={airportQuizHandler}
              isLinkLayout={false}
              userAvatarImage={profileAvatar}
              className="padding-hor-24"
              clickAvatarCallback={clickProfileAvatar}
              handleSelectChange={handleSelectChange}
              inputIconRight={FilterIconGray}
              inputPlaceholder="Where to?"
              inputSearchResultOptions={inputSearchResultOptions}
              onChangeInput={handleInputChange}
              showInput={showInputState}
              onClickIconRight={clickFilterIcon}
              showLogo={true}
              inputSearchClick={() => {}}
              hasIconRightButton={true}
              searchInput
              clickOnLogo={clickOnLogo}
              onClickLogin={onClickLogin}
              listFavoriteFlights={listFavoriteFlights}
            />
          </section>
          <LogInSignUpDesk
            advertisement={slides}
            component={<BestOffersCatalogPage />}
          />
        </>
      ) : ( */}
      <Desk
        listFavoriteFlights={listFavoriteFlights}
        clickProfileAvatar={clickProfileAvatar}
        profileAvatar={profileAvatar}
        principalMain={componentPrincipal}
        secondaryMain={componentSecondary}
        tertiaryMain={componentTertiary}
        airportsPins={airportsPinsState}
        majorMain={mainScreen}
        destinyCoordinates={destinyCoordinates}
        originCoordinates={originCoordinates}
      />
      {/* )} */}
    </>
  );
};

export default HomePage;
