import React, { useCallback, useEffect, useRef, useState } from "react";
import "./styles.scss";
import TravelOffersCard, {
  TravelOffers,
} from "components/Cards/TravelOffersCard";
import { FlightAvailability } from "types/flights";
import { useUserInfo } from "contexts/userInfo";
import { useNavigate, useParams } from "react-router-dom";
import { useSearchedFlight } from "contexts/useSearchedFlight";
import { useSearchOriginDestiny } from "contexts/searchOriginDestiny";
import { useDeskNavigation } from "contexts/useDeskNavigation";
import getFlights from "api/flights/getFlights";
import { clearLocalStorage } from "utils/clearLocalStorage";
import destination1 from "../../assets/images/offer-destinations-images/destination1.png";
import destination2 from "../../assets/images/offer-destinations-images/destination2.png";
import destination3 from "../../assets/images/offer-destinations-images/destination3.png";
import destination4 from "../../assets/images/offer-destinations-images/destination4.png";
import destination5 from "../../assets/images/offer-destinations-images/destination5.png";
import destination6 from "../../assets/images/offer-destinations-images/destination6.png";
import { getAirportsByIATA } from "utils/getAirportsByIATA";
import { useWindowWidthSize } from "contexts/useWindowWidthSize";
import { useIconContext } from "contexts/useIconNav";

const BestOffersCatalogPage: React.FC = () => {
  const { userInfo } = useUserInfo();
  const navigate = useNavigate();
  const { activeIcon, handleButtonClick } = useIconContext();

  const { setSearchedFlight } = useSearchedFlight();

  const { originDestiny } = useSearchOriginDestiny();
  const { setSecondaryScreen, setPrincipalScreen } = useDeskNavigation();
  const [flightsList, setFlights] = useState<TravelOffers[]>([]);
  const [skipReq, setSkipReq] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const observer = useRef<IntersectionObserver | null>(null);
  const [hasNoFlights, setHasNoFlights] = useState(false);
  const [selectedFlightId, setSelectedFlightId] = useState("");

  const [restFlightsToSee, setRestFlightsToSee] = useState(0);
  const [classWidth, setClassWidth] = useState("");
  const cardsLayoutRef = useRef<HTMLElement | null>(null);
  const [templateGridChanged, setTemplateChanged] = useState(false);
  const { flight_id } = useParams();

  const { windowSize } = useWindowWidthSize();

  useEffect(() => {
    if (flight_id && activeIcon !== "grid") {
      setTemplateChanged(true);
      setSelectedFlightId(flight_id);
    }
  }, [flight_id]);

  const imagesMockArr = [
    destination1,
    destination2,
    destination3,
    destination4,
    destination5,
    destination6,
  ];

  useEffect(() => {
    listFlights();
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === cardsLayoutRef.current) {
          const { width } = entry.contentRect;

          let size = Math.floor(width + 48);

          if (size < 560) {
            setClassWidth("mobile-width");
          } else if (size >= 560 && size < 760) {
            setClassWidth("mobile-width-plus");
          } else if (size >= 760 && size < 1024) {
            setClassWidth("super-mobile-width");
          } else if (size >= 1024 && size < 1140) {
            setClassWidth("tablet-width");
          } else if (size >= 1140 && size < 1600) {
            setClassWidth("widescreen-width");
          } else {
            setClassWidth("hyper-widescreen-width");
          }
        }
      }
    });

    if (cardsLayoutRef.current) {
      resizeObserver.observe(cardsLayoutRef.current);
    }

    return () => {
      if (cardsLayoutRef.current) {
        resizeObserver.unobserve(cardsLayoutRef.current);
      }
    };
  }, []);

  const getCityByIATA = async (iata: string) => {
    const token = userInfo["@air-fly_access-token"];
    return await getAirportsByIATA(token, iata);
  };

  const listFlights = useCallback(
    async (reset = false) => {
      if (isLoading || (!hasMore && !reset)) return;
      setIsLoading(true);

      const params: Record<string, any> = {};

      if (!reset && skipReq) {
        params.skip = skipReq;
      }

      if (originDestiny.destiny.iata) {
        params.destination = originDestiny.destiny.iata;
      }

      if (originDestiny.origin.iata) {
        params.origin = originDestiny.origin.iata;
      }

      if (originDestiny.destiny.direct) {
        params.direct = originDestiny.destiny.direct;
      }

      if (originDestiny.destiny.date) {
        params.date = originDestiny.destiny.date;
      }

      if (originDestiny.destiny.classes) {
        params.classes = originDestiny.destiny.classes;
      }

      params.take = 15;

      try {
        const request = await getFlights(
          userInfo["@air-fly_access-token"],
          params
        );

        if (request.status === 200) {
          const newFlights: FlightAvailability[] = request.res.data;

          const prepareData: TravelOffers[] = await Promise.all(
            newFlights.map(async (offer, index) => {
              const departure = await getCityByIATA(offer.route.originAirport);
              const destination = await getCityByIATA(
                offer.route.destinationAirport
              );

              const filteredArray = Array.from(
                new Set(
                  [
                    offer.YMileageCost,
                    offer.WMileageCost,
                    offer.JMileageCost,
                    offer.FMileageCost,
                  ]
                    .map((milesCost) => Number(milesCost))
                    .filter((miles) => miles > 0)
                )
              );

              const priceToShow =
                filteredArray.length > 0 ? Math.min(...filteredArray) : 0;

              return {
                departure,
                image: imagesMockArr[index % imagesMockArr.length],
                destination,
                points: priceToShow.toLocaleString("en-US"),
                id: offer.id,
                flight: offer,
              };
            })
          );

          if (reset) {
            setFlights(prepareData);
          } else {
            setFlights((prev) => [...prev, ...prepareData]);
          }

          const totalFlights = request.res.total;
          const currentFlightsCount = reset
            ? newFlights.length
            : flightsList.length + newFlights.length;

          setHasMore(currentFlightsCount < totalFlights);
          setRestFlightsToSee(totalFlights);

          if (newFlights.length > 0) {
            setSkipReq((prev) => prev + 15);
          } else {
            setHasMore(false);
          }

          if (newFlights.length === 0) {
            setSecondaryScreen("");
            setHasNoFlights(true);
          }
        } else if (request.status === 401) {
          clearLocalStorage();
          navigate("/app");
        } else {
          setHasMore(false);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [hasMore, skipReq, userInfo, originDestiny.destiny, flightsList.length]
  );

  const detailsCardHandler = (offer: TravelOffers) => {
    setSearchedFlight(offer.flight);
    setSelectedFlightId(offer.id.toString());

    navigate(`/home/${offer.id.toString()}`);
  };

  return (
    <div className="best-offers-catalog">
      <main className={`offers-cards-main`} ref={cardsLayoutRef}>
        {flightsList.map((offer, index) => {
          const templateNumber =
            windowSize >= 1780 && windowSize < 2074 && templateGridChanged
              ? 3
              : windowSize >= 2074 && templateGridChanged
              ? 4
              : 2;
          const rowIndex = Math.floor(index / templateNumber);

          const isEvenRow = rowIndex % templateNumber === 0;
          const cardClass =
            index % templateNumber === 0
              ? isEvenRow
                ? `layout-principal`
                : `layout-secondary`
              : index % templateNumber === 1
              ? isEvenRow
                ? `layout-secondary`
                : `layout-principal`
              : // : index % templateNumber === 2
                // ? isEvenRow
                //   ? `layout-secondary`
                //   : `layout-principal`
                `layout-secondary`;

          return (
            <TravelOffersCard
              detailsHandler={() => detailsCardHandler(offer)}
              key={index}
              travelOffers={offer}
              className={`${cardClass} ${
                templateGridChanged ? "changed" : ""
              } ${classWidth}`}
              isSelected={offer.id === selectedFlightId}
            />
          );
        })}
      </main>
    </div>
  );
};

export default BestOffersCatalogPage;
