import React, { useEffect, useState } from "react";
import "./styles.scss";
import travelLine from "../../../assets/icons/arrows/destination-route-line-hor-gray.svg";
import AirPlaneFaded from "../../../assets/icons/airPlanes/airplane-faded.svg";
import BellFaded from "../../../assets/icons/bells/bell-faded.svg";
import chevronRightOrange from "../../../assets/icons/arrows/chevron-right-orange.svg";
import { useUserInfo } from "contexts/userInfo";
import HeartFaded from "../../../assets/icons/heart/heart-icon-faded.svg";
import FlightClassAvailable from "components/FlightClassAvailable";
import SeatGrayIcon from "../../../assets/icons/seat/seat-arrow-gray.svg";
import { getAirportsByIATA } from "utils/getAirportsByIATA";

interface AirlinesSimpleTicketsProps {
  ticket: Ticket;
  cardClick: (id: string) => void;
  isSelected: boolean;
  isALikedFlight: boolean;
  isANotifiedFlight: boolean;
}

type Ticket = {
  destination: InfoTravel;
  origin: InfoTravel;
  seatType: string[];
  price: number;
  travelTime?: string;
  id: string;
  stops?: string | number;
  soldOut?: boolean;
};

type InfoTravel = {
  airport: string;
  time: Date | string;
  date: Date | string;
};

const AirlinesSimpleTickets: React.FC<AirlinesSimpleTicketsProps> = (props) => {
  const { ticket, cardClick, isSelected, isALikedFlight, isANotifiedFlight } =
    props;
  const [destinationAirport, setDestinationAirport] = useState<string>("");
  const [originAirport, setOriginAirport] = useState<string>("");
  const [iconToShow, setIconToShow] = useState("");
  const { userInfo } = useUserInfo();

  useEffect(() => {
    const getCityByIATA = async () => {
      const token = userInfo["@air-fly_access-token"];
      const destination = await getAirportsByIATA(
        token,
        ticket.destination.airport
      );
      const origin = await getAirportsByIATA(token, ticket.origin.airport);

      setDestinationAirport(destination);
      setOriginAirport(origin);
    };

    getCityByIATA();

    setIconToShow(
      isALikedFlight
        ? HeartFaded
        : isANotifiedFlight
        ? BellFaded
        : AirPlaneFaded
    );
  }, [ticket.destination.airport, ticket.origin.airport]);

  const formatNumberUS = (number: number) => number.toLocaleString("en-US");
  const formatToShortDate = (isoString: string | Date): string => {
    const date = new Date(isoString);

    const options: Intl.DateTimeFormatOptions = {
      weekday: "short",
      month: "short",
      day: "numeric",
      timeZone: "UTC",
    };

    return date.toLocaleDateString("en-US", options);
  };

  const clickOnCard = () => {
    cardClick(ticket.id);
  };

  return (
    <div className={`airline-simple-tickets-cards no-padding`}>
      <div
        key={ticket.id}
        className={`airline-simple-tickets-li ${
          isSelected ? "ticket-selected" : ""
        }`}
        onClick={clickOnCard}
      >
        <section className="airline-simple-tickets__from">
          <p className="no-margin header-section-text">FROM</p>
          <p className="no-margin airport-acronym">{ticket.origin.airport}</p>
          <p className="no-margin airport-city">{originAirport}</p>
        </section>
        <section className="airline-simple-tickets__details">
          <img src={travelLine} alt="line travel" />
          <p className="no-margin travel-week-date">
            {formatToShortDate(ticket.origin.date)}
          </p>
        </section>
        <section className="airline-simple-tickets__to">
          <p className="no-margin header-section-text">TO</p>
          <p className="no-margin airport-acronym">
            {ticket.destination.airport}
          </p>
          <p className="no-margin airport-city">{destinationAirport}</p>
        </section>
        <hr className={`dotted-hr`} />
        <section className="seat-price-ticket-container">
          {ticket.soldOut ? (
            <p className="seat-price-ticket-container__sold-out">Sold Out</p>
          ) : (
            <div className="details__line">
              <div className="seat-icon-type">
                <img src={iconToShow} alt="airplane icon" />
                <FlightClassAvailable seatsAvailableList={ticket.seatType} />
              </div>

              <div className="ticket-price-and-type">
                <div className="ticket-price-and-type__prices">
                  <p className="no-margin ticket-price">
                    {formatNumberUS(Number(ticket.price))}
                  </p>
                </div>
                {Number(ticket.price) !== 0 && (
                  <img src={chevronRightOrange} alt="chevron right" />
                )}
              </div>
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default AirlinesSimpleTickets;
