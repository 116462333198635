import React, { ReactNode } from "react";
import "./styles.scss";
import ProfileIcon from "../../assets/icons/avatars/profile-icon-gray.svg";
import HeartIconFillOrange from "../../assets/icons/heart/heart-icon-fill-orange.svg";
import HeartIconGray from "../../assets/icons/heart/heart-icon-gray.svg";
import PlusOrange from "../../assets/icons/arithmetic/plus-orange.svg";
import FlagIcon from "../../assets/icons/flags/flag-icon-orange.svg";
import ShareIcon from "../../assets/icons/share/share-icon-orange.svg";
import CopyIcon from "../../assets/icons/copy/copy-icon-gray.svg";
import BellGray from "../../assets/icons/bells/bell-gray.svg";

interface PageTitleProps {
  title?: ReactNode;
  subtitle?: ReactNode;
  onBackClick?: () => void;
  isChat?: boolean;
  avatarUrl?: string;
  isOnLine?: boolean;
  mediaButtonClick?: (a: any) => void;
  hasCloseIcon?: boolean;
  chatLocked?: boolean;
  hasAMomentToSee?: boolean;
  clickMoment?: (a: any) => void;
  className?: string;
  hasButtonNavigation?: boolean;
  backgroundColor?: "white" | "chalk" | "transparent";
  buttonWhite?: boolean;
  buttonToLike?: boolean;
  likeButtonAction?: (a: any) => void;
  liked?: boolean;
  buttonToAdd?: boolean;
  addButtonAction?: (a: any) => void;
  buttonFlagAction?: (a: any) => void;
  buttonFlag?: boolean;
  shareIcon?: boolean;
  shareIconAction?: (a: any) => void;
  rightButtonWhite?: boolean;
  buttonCopy?: boolean;
  buttonCopyAction?: (a: any) => void;
  rightButtonChalk?: boolean;
  buttonAlertAction?: (a: any) => void;
  buttonAlert?: boolean;
}

const PageTitle: React.FC<PageTitleProps> = ({
  title,
  rightButtonChalk,
  subtitle,
  onBackClick,
  isChat,
  avatarUrl,
  isOnLine,
  mediaButtonClick,
  hasCloseIcon = true,
  chatLocked,
  hasAMomentToSee,
  clickMoment,
  className,
  hasButtonNavigation = true,
  backgroundColor,
  buttonWhite,
  buttonToLike,
  likeButtonAction,
  liked,
  buttonToAdd,
  addButtonAction,
  buttonFlag,
  buttonFlagAction,
  shareIcon,
  shareIconAction,
  rightButtonWhite,
  buttonCopy,
  buttonCopyAction,
  buttonAlert,
  buttonAlertAction,
}) => {
  const handleBackClick = () => {
    if (onBackClick) {
      onBackClick();
    }
  };

  const principalContainerClasses = [
    "page-title-container",
    className ?? "",
    backgroundColor === "chalk"
      ? "chalk-background"
      : backgroundColor === "transparent"
      ? "transparent-background"
      : "white-background",
  ]
    .join(" ")
    .trim();

  return (
    <div className={principalContainerClasses}>
      {hasButtonNavigation && (
        <div
          className={`back-button ${buttonWhite ? "button-white" : ""}`}
          onClick={handleBackClick}
        >
          {hasCloseIcon ? (
            <span className="icon icon-close icon-md"></span>
          ) : (
            <span className="icon icon-chevron-left icon-md icon-gray"></span>
          )}
        </div>
      )}

      {isChat && clickMoment && (
        <div
          onClick={clickMoment}
          className={`chat-icon margin-right-8 ${
            hasAMomentToSee ? "new-moment-available" : "moment-was-seen"
          }`}
        >
          <img
            className="chat-icon-img padding-4"
            src={avatarUrl || ProfileIcon}
            alt="chat icon"
          />
        </div>
      )}
      <div className="text-wrapper">
        <div className={`title ${isChat ? "chat-title" : ""}`}>{title}</div>
        {subtitle && (
          <div className="subtitle-wrapper">
            {isChat && (
              <div
                className={`chat-icon-status ${
                  isOnLine ? "interest-online" : "interest-offline"
                }`}
              />
            )}
            <p className="no-margin subtitle">{subtitle}</p>
          </div>
        )}
      </div>
      {buttonCopy && (
        <div className="add-container">
          <button className={`add-button copy`} onClick={buttonCopyAction}>
            <img src={CopyIcon} alt="add" />
          </button>
        </div>
      )}

      {shareIcon && (
        <div className={`add-container`}>
          <button
            className={`add-button ${rightButtonWhite ? "button-white" : ""}`}
            onClick={shareIconAction}
          >
            <img src={ShareIcon} alt="add" />
          </button>
        </div>
      )}
      {buttonToLike && (
        <div className="like-route">
          <button
            className={`like-button ${liked ? "liked" : ""}`}
            onClick={likeButtonAction}
          >
            <img
              src={liked ? HeartIconFillOrange : HeartIconGray}
              alt="heart like"
            />
          </button>
        </div>
      )}

      {buttonAlert && (
        <div className={`add-container`}>
          <button
            className={`add-button ${
              rightButtonWhite
                ? "button-white"
                : rightButtonChalk
                ? "button-chalk"
                : ""
            }`}
            onClick={buttonAlertAction}
          >
            <img src={BellGray} alt="bell" />
          </button>
        </div>
      )}

      {buttonToAdd && (
        <div className="add-container">
          <button
            className={`add-button ${rightButtonWhite ? "button-white" : ""}`}
            onClick={addButtonAction}
          >
            <img src={PlusOrange} alt="add" />
          </button>
        </div>
      )}

      {buttonFlag && (
        <div className="quiz-container">
          <button
            className={`quiz-button ${rightButtonWhite ? "button-white" : ""}`}
            onClick={buttonFlagAction}
          >
            <img src={FlagIcon} alt="flag" />
          </button>
        </div>
      )}

      {isChat && !chatLocked && (
        <div className="open-media">
          <button className="open-media-button" onClick={mediaButtonClick}>
            MEDIA
          </button>
        </div>
      )}
    </div>
  );
};

export default PageTitle;
