import { useEffect, useState } from "react";
import "./styles.scss";

import PageTitle from "components/PageTitles";
import Button from "../../components/Buttons/Button";
import InputRadioSub from "components/Inputs/InputRadioSub";

import { Plan } from "types/interfaces";

import { useDeskNavigation } from "contexts/useDeskNavigation";
import { useUserInfo } from "contexts/userInfo";

import getPlans from "api/plans/getPlans";
import postPlans from "api/plans/postPlans";
import getCurrentPlan from "api/plans/getCurrentPlan";

import infoIcon from "../../assets/icons/info/union-circle-gray.svg";
import InputText from "components/Inputs/InputText";
import postCoupon from "api/plans/postCoupon";
import { IToast } from "types/toast";
import Toast from "components/Toast";
import deleteSubscription from "api/plans/deleteSubsription";
import { useModal } from "contexts/useModal";

const UserSettingsSubscriptionPage: React.FC = () => {
  const { userInfo, setUserInfo } = useUserInfo();
  const [chosenType, setChosenType] = useState("");
  const [plans, setPlans] = useState<Plan[]>([]);
  const [userCurrentPlan, setUserCurrentPlan] = useState([]);
  const [coupon, setCoupon] = useState("");
  const { setModalContent, setOpenUseModal } = useModal();
  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });

  const { setPrincipalScreen, setSecondaryScreen } = useDeskNavigation();

  useEffect(() => {
    currentPlan();
    fetchPlans();
  }, []);

  const currentPlan = async () => {
    try {
      const request = await getCurrentPlan(userInfo["@air-fly_access-token"]);

      switch (request.status) {
        case 200:
          setUserCurrentPlan(request.res.plan.name);
          setTimeout(() => {
            setUserCurrentPlan(request.res.plan.name);
            setChosenType(request.res.plan.name.toLowerCase());
          }, 500);

          handleOptionChange(request.res.plan.name.toLowerCase());
          break;
        case 404:
          setUserCurrentPlan([]);
          setChosenType("basic");
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPlans = async () => {
    try {
      const request = await getPlans(userInfo["@air-fly_access-token"]);
      switch (request.status) {
        case 200:
          setPlans(request.res);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
      setPlans([]);
    }
  };

  const handleOptionChange = (value: string) => {
    setChosenType(value);
  };

  const handleSubscribe = async () => {
    const token = userInfo["@air-fly_access-token"];
    const planId = plans.find(
      (plan) => plan.name === chosenType.toLowerCase()
    )?.id;

    try {
      if (planId) {
        const response = await postPlans(token, planId);

        if (response.status === 201) {
          const checkoutUrl = response.res.checkoutUrl;
          window.location.href = checkoutUrl;
        } else {
          setToastShow(true);
          setToastConfig({
            description: "Unexpected Error, Try Again",
            title: "Oops...",
            type: "warning",
          });

          setTimeout(() => {
            setToastShow(false);
          }, 5000);
        }
      }
    } catch (error) {
      console.error(error);
      setToastShow(true);
      setToastConfig({
        description: "Unexpected Error, Try Again",
        title: "Oops...",
        type: "warning",
      });

      setTimeout(() => {
        setToastShow(false);
      }, 5000);
    }

    // if (coupon === "AMIGOS") {
    //   try {
    //     if (planId) {
    //       const response = await postCoupon(token, { planId, coupon });

    //       if (response.status === 201) {
    //         window.location.href = `/home?paymentStatus=success`;
    //         setUserInfo("@air-fly_user-plan", chosenType);
    //       } else {
    //         window.location.href = `/home?paymentStatus=failed`;
    //       }
    //     }
    //   } catch (error) {
    //     console.error(error);
    //     window.location.href = `/home?paymentStatus=failed`;
    //   }
    // } else {
    //   setToastShow(true);
    //   setCoupon("");
    //   setToastConfig({
    //     description: "Invalid Promo Code. Try 'AMIGOS' ",
    //     title: "Oops...",
    //     type: "warning",
    //   });

    //   setTimeout(() => {
    //     setToastShow(false);
    //   }, 5000);
    // }
  };

  const handleUnsubscribe = () => {
    setModalContent(
      <section className="payment-response">
        <span className="icon icon-info icon-md icon-orange"></span>
        <h5 className="no-margin margin-top-16">
          <b>Cancel Subscription</b>
        </h5>
        <p className="small-p margin-bottom-16">Are you sure?</p>
        <Button
          children={"Back to the App"}
          buttonStyle={"primary"}
          onClick={() => {
            setOpenUseModal(false);
          }}
        />
        <Button
          children={"Cancel Subscription"}
          buttonStyle={"quintenary"}
          onClick={async () => {
            const token = userInfo["@air-fly_access-token"];

            try {
              if (token) {
                const response = await deleteSubscription(token);

                if (response.status === 201) {
                  setOpenUseModal(false);
                } else {
                  setToastShow(true);
                  setToastConfig({
                    description: "Unexpected Error, Try Again",
                    title: "Oops...",
                    type: "warning",
                  });

                  setTimeout(() => {
                    setToastShow(false);
                  }, 5000);
                }
              }
            } catch (error) {
              console.error(error);
              setToastShow(true);
              setToastConfig({
                description: "Unexpected Error, Try Again",
                title: "Oops...",
                type: "warning",
              });

              setTimeout(() => {
                setToastShow(false);
              }, 5000);
            }
          }}
        />
      </section>
    );
    setOpenUseModal(true);
  };

  return (
    <div className="user-settings-subscription">
      <PageTitle
        title={"Subscription"}
        backgroundColor="transparent"
        subtitle={"Manage Plans"}
        onBackClick={() => {
          setPrincipalScreen("/user-settings");
          setSecondaryScreen("");
        }}
        hasCloseIcon={false}
        className="padding-hor-24"
      />
      <div className="airfly-container">
        <InputRadioSub
          key={0}
          title={"AirFly Basic"}
          price={"Free"}
          description={
            "Your starting point, where you can start explore the world."
          }
          value={"basic"}
          icon={infoIcon}
          groupName={"options"}
          checked={chosenType === "basic"}
          onChange={handleOptionChange}
          benefits={[
            "Access up to 5 flight options, with destinations chosen randomly.",
            "Flights are based on departures from airports near you.",
            "No alert options included.",
          ]}
        />
        <InputRadioSub
          key={1}
          title={"AirFly Travel"}
          price={"$4.99"}
          description={
            "Enjoy unlimited flight updates daily, ensuring you never miss important travel opportunities."
          }
          value={"travel"}
          icon={infoIcon}
          groupName={"options"}
          checked={chosenType === "travel"}
          onChange={handleOptionChange}
          benefits={[
            "View all flights departing from airports near you.",
            "Includes up to 1 alerts, limited to date, origin and destination only.",
            "No advanced filters available.",
          ]}
        />
        <InputRadioSub
          key={2}
          title={"AirFly Global"}
          price={"$7.99"}
          description={
            "Get unlimited updates with advanced features like smart filtering to tailor notifications to your travel needs."
          }
          value={"global"}
          icon={infoIcon}
          groupName={"options"}
          checked={chosenType === "global"}
          onChange={handleOptionChange}
          benefits={[
            "All benefits in AirFly Travel",
            "Access all flights, with advanced filtering options.",
            "Receive unlimited alerts with detailed options, ensuring you never miss the perfect flight deal.",
          ]}
        />

        <div className="buttons-container_subscription">
          <Button
            buttonStyle="primary"
            onClick={handleSubscribe}
            disabled={chosenType === "basic"}
          >
            Subscribe
          </Button>
          <Button
            buttonStyle="quintenary"
            onClick={handleUnsubscribe}
            disabled={chosenType === "basic"}
          >
            Cancel Subscription
          </Button>
        </div>
      </div>
      <Toast
        type={toastConfig.type}
        isVisible={toastShow}
        setIsVisible={setToastShow}
        title={toastConfig.title}
        description={toastConfig.description}
      />
    </div>
  );
};

export default UserSettingsSubscriptionPage;
