import React from "react";
import "./styles.scss";

interface SeparatorProps {
  className?: string;
  marginVert?: 0 | 4 | 8 | 16 | 24 | 40;
}

const Separator: React.FC<SeparatorProps> = (props) => {
  const { className, marginVert = 40 } = props;

  const margin = marginVert === 0 ? "no-margin" : `margin-vert-${marginVert}`;

  const separatorClasses = ["separator-component", margin, className]
    .join(" ")
    .trim();

  return <hr className={separatorClasses} />;
};

export default Separator;
