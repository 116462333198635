import React, { useCallback, useEffect, useState } from "react";
import "./styles.scss";
import SearchFlightFilterBar from "components/SearchFlightFilterBar";
import HeaderNavigate from "components/Headers/HeaderNavigate";
import { useDeskNavigation } from "contexts/useDeskNavigation";
import { useUserInfo } from "contexts/userInfo";
import { IAirportsSearch } from "types/airportsSearch";
import { useSearchOriginDestiny } from "contexts/searchOriginDestiny";
import { clearLocalStorage } from "utils/clearLocalStorage";
import { useNavigate } from "react-router-dom";
import ToolBar, { ButtonsToolbar } from "components/ToolBar";
import { useWindowWidthSize } from "contexts/useWindowWidthSize";
import { useIconContext } from "contexts/useIconNav";
import { useModal } from "contexts/useModal";
import ModalFilterFlights from "components/Modal/ModalFilterFlights";
import { IOptionInputTextSelectProps } from "components/Inputs/InputTextSelect";
import Button from "components/Buttons/Button";
import TravelOffersCard, {
  TravelOffers,
} from "components/Cards/TravelOffersCard";
import getFlights from "api/flights/getFlights";
import { FlightAvailability } from "types/flights";
import { getAirportsByIATA } from "utils/getAirportsByIATA";
import travelOffersMock from "../../mocks/travelOffers";
import { useSearchedFlight } from "contexts/useSearchedFlight";
import ExplanationImage from "../../assets/images/airplane-panoramic.png";
import pinIconBlack from "../../assets/icons/pin/pin-icon-black.svg";
import faceIconBlack from "../../assets/icons/avatars/face-icon-black.svg";
import globeIconBlack from "../../assets/icons/globes/globe-icon-black.svg";
import { FilterFlightObj } from "types/filter";
import { searchFilteredConsultCallback } from "utils/searchFilteredConsultCallback";
import { useUserNearbyAirports } from "hooks/useNearbyAirports";

const ShowCasePage: React.FC = () => {
  const [profileAvatar, setProfileAvatar] = useState("");
  const [isSlideUpVisible, setIsSlidUpVisible] = useState(false);
  const [selectedFlightId, setSelectedFlightId] = useState("");

  const [inputSearchResultOptions, setInputSearchResultOptions] = useState(
    {} as IOptionInputTextSelectProps[]
  );
  const [userCoordinates, setUserCoordinates] = useState({
    origin: {
      iataCode: "",
      lat: 0,
      long: 0,
    },
    destination: {
      iataCode: "",
      lat: 0,
      long: 0,
    },
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { windowSize } = useWindowWidthSize();
  const { setModalContent, setOpenUseModal } = useModal();
  const { handleButtonClick, getIconClass } = useIconContext();
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [flightsList, setFlights] = useState<TravelOffers[]>([]);
  const [restFlightsToSee, setRestFlightsToSee] = useState(0);
  const [hasNoFlights, setHasNoFlights] = useState(false);
  const { setSearchedFlight } = useSearchedFlight();

  const { userInfo, setUserInfo } = useUserInfo();
  const { originDestiny, setOriginDestiny } = useSearchOriginDestiny();
  const navigate = useNavigate();
  const [skipReq, setSkipReq] = useState(0);
  const { nearbyAirports } = useUserNearbyAirports();

  useEffect(() => {
    setProfileAvatar(userInfo["@air-fly_user_avatar"]);

    getCoordinatesAirports();
    listFlights();
  }, []);

  const getCoordinatesAirports = async () => {
    const airportsNearby: IAirportsSearch[] = nearbyAirports;

    let originCoords = {
      lat: airportsNearby[0].latitude,
      lon: airportsNearby[0].longitude,
      iataCode: airportsNearby[0].iata,
    };

    if (originDestiny.origin.lat && originDestiny.origin.lon) {
      originCoords = {
        iataCode: originDestiny.origin.iata || "",
        lat: originDestiny.origin.lat,
        lon: originDestiny.origin.lon,
      };
    }
    setUserCoordinates({
      origin: {
        iataCode: originCoords.iataCode,
        lat: originCoords.lat,
        long: originCoords.lon,
      },
      destination: {
        iataCode: originDestiny.destiny.iata || "",
        lat: originDestiny.destiny.lat,
        long: originDestiny.destiny.lon,
      },
    });
  };

  const {
    setMainScreen,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
  } = useDeskNavigation();

  const airportQuizHandler = () => {
    setMainScreen("/airport-quiz");
    setPrincipalScreen("");
    setSecondaryScreen("");
    setTertiaryScreen("");
  };

  const listFlightsPage = () => {
    setMainScreen("");
    setSecondaryScreen("");
    setTertiaryScreen("");
    setPrincipalScreen("/search-milesy");
  };

  const alertHandler = () => {
    setMainScreen("");
    setPrincipalScreen("/my-alerts");
    setSecondaryScreen("");
    setTertiaryScreen("");
  };

  const clickProfileAvatar = () => {
    setMainScreen("");
    setSecondaryScreen("");
    setTertiaryScreen("");
    setPrincipalScreen("/user-settings");
  };

  const listFavoriteFlights = () => {
    setOpenUseModal(false);

    setMainScreen("");
    setSecondaryScreen("");
    setTertiaryScreen("");
    setPrincipalScreen("/favorites-flies");
  };

  const clickOnLogo = () => {};
  const onClickLogin = () => {};

  const buttonToolbar: ButtonsToolbar[] = [
    {
      action: () => {
        handleButtonClick("grid", listFlightsPage);
        setIsSlidUpVisible(false);
      },
      icon: "icon-grid",
      text: "",
      className: getIconClass("grid"),
    },
    {
      action: () => {
        handleButtonClick("heart-outline", listFavoriteFlights);
        setIsSlidUpVisible(false);
      },
      icon: "icon-heart-outline",
      text: "",
      className: getIconClass("heart-outline"),
    },
    {
      action: () => {
        handleButtonClick("notification", alertHandler);
        setIsSlidUpVisible(false);
      },
      icon: "icon-notification",
      text: "",
      className: getIconClass("notification"),
    },
    {
      action: () => {
        handleButtonClick("flag", airportQuizHandler);
        setIsSlidUpVisible(false);
      },
      icon: "icon-flag",
      text: "",
      className: getIconClass("flag"),
    },
  ];

  const clickFilterIcon = () => {
    // if (userInfo["@air-fly_user-plan"] === "global") {
    if (windowSize <= 768) {
      setIsSlidUpVisible((prev) => !prev);
    } else {
      setModalContent(
        <ModalFilterFlights
          inputSearchResultOptions={inputSearchResultOptions}
          titleHead="Add Filter"
          titleText="Destination"
          primaryButtonText="Add Filter"
          filterResultsCallback={(data) =>
            searchFilteredConsultCallback(data, setOriginDestiny)
          }
        />
      );
      setTimeout(() => {
        setOpenUseModal(true);
      }, 0);
    }
    // } else {
    //   setModalContent(
    //     <section className="payment-response">
    //       <span className="icon icon-airplane icon-md icon-orange "></span>
    //       <h5 className="no-margin margin-top-16">
    //         <b>Oops, you need to upgrade your plan!</b>
    //       </h5>
    //       <p className="small-p margin-bottom-16">
    //         Unlock all filters with our premium plans!
    //       </p>

    //       <div className="buttons-container">
    //         <Button
    //           children={"Upgrade Now"}
    //           buttonStyle={"primary"}
    //           onClick={() => {
    //             handleTryAgain();
    //           }}
    //         />
    //         <Button
    //           children={"Continue Free"}
    //           buttonStyle={"tertiary"}
    //           onClick={() => {
    //             setOpenUseModal(false);
    //           }}
    //         />
    //       </div>
    //     </section>
    //   );
    //   setOpenUseModal(true);
    // }
  };

  const getCityByIATA = async (iata: string) => {
    const token = userInfo["@air-fly_access-token"];
    return await getAirportsByIATA(token, iata);
  };

  const listFlights = useCallback(
    async (reset = false) => {
      if (isLoading || (!hasMore && !reset)) return;
      setIsLoading(true);
      console.log("here");
      const params: Record<string, any> = {};

      if (!reset && skipReq) {
        params.skip = skipReq;
      }

      if (originDestiny.destiny.iata) {
        params.destination = originDestiny.destiny.iata;
      }

      if (originDestiny.origin.iata) {
        params.origin = originDestiny.origin.iata;
      }

      if (originDestiny.destiny.direct) {
        params.direct = originDestiny.destiny.direct;
      }

      if (originDestiny.destiny.date) {
        params.date = originDestiny.destiny.date;
      }

      if (originDestiny.destiny.classes) {
        params.classes = originDestiny.destiny.classes;
      }

      params.take = 15;

      try {
        const request = await getFlights(
          userInfo["@air-fly_access-token"],
          params
        );

        if (request.status === 200) {
          const newFlights: FlightAvailability[] = request.res.data;

          const prepareData: TravelOffers[] = await Promise.all(
            newFlights.map(async (offer, index) => {
              const departure = await getCityByIATA(offer.route.originAirport);
              const destination = await getCityByIATA(
                offer.route.destinationAirport
              );

              const filteredArray = Array.from(
                new Set(
                  [
                    offer.YMileageCost,
                    offer.WMileageCost,
                    offer.JMileageCost,
                    offer.FMileageCost,
                  ]
                    .map((milesCost) => Number(milesCost))
                    .filter((miles) => miles > 0)
                )
              );

              const priceToShow =
                filteredArray.length > 0 ? Math.min(...filteredArray) : 0;

              return {
                departure,
                image: travelOffersMock[index % travelOffersMock.length]?.image,
                destination,
                points: priceToShow.toLocaleString("en-US"),
                id: offer.id,
                flight: offer,
              };
            })
          );
          console.log(prepareData);
          if (reset) {
            setFlights(prepareData);
          } else {
            setFlights((prev) => [...prev, ...prepareData]);
          }

          const totalFlights = request.res.total;
          const currentFlightsCount = reset
            ? newFlights.length
            : flightsList.length + newFlights.length;

          setHasMore(currentFlightsCount < totalFlights);
          setRestFlightsToSee(totalFlights);

          if (newFlights.length > 0) {
            setSkipReq((prev) => prev + 15);
          } else {
            setHasMore(false);
          }

          if (newFlights.length === 0) {
            setSecondaryScreen("");
            setHasNoFlights(true);
          }
        } else if (request.status === 401) {
          clearLocalStorage();
          navigate("/app");
        } else {
          setHasMore(false);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [hasMore, skipReq, userInfo, originDestiny.destiny, flightsList.length]
  );

  const detailsCardHandler = (offer: TravelOffers) => {
    setSearchedFlight(offer.flight);
    setSelectedFlightId(offer.id.toString());

    navigate(`/home/${offer.id.toString()}`);
  };

  return (
    <div className="show-case-page">
      <HeaderNavigate
        isLinkLayout={false}
        userAvatarImage={profileAvatar}
        className="padding-hor-24"
        clickAvatarCallback={clickProfileAvatar}
        showLogo
        clickOnLogo={clickOnLogo}
        onClickLogin={onClickLogin}
      />
      <main className="show-case-page__main">
        <SearchFlightFilterBar />
        <section className="main__explanation-card">
          <img src={ExplanationImage} alt="travel explanation" />
          <div className="explanation-card__text-wrapper">
            <h1 className="text-wrapper__title no-margin">SMART or PREMIUM?</h1>
            <p className="text-wrapper__paragraph no-margin">
              Experience luxury travel with our business-class comfort,
              including hand-stitched leather seats and complimentary WiFi, or
              indulge in first-class with exclusive lounge access and extra seat
              space for ultimate comfort.
            </p>
            <div className="text-wrapper__icon-text-wrapper">
              <span className="icon-text-wrapper__element">
                <img
                  src={globeIconBlack}
                  alt="globe icon"
                  className="icon-text-wrapper__icon"
                />
                <p className="element__text">Business Travel</p>
              </span>
              <span className="icon-text-wrapper__element">
                <img
                  src={pinIconBlack}
                  alt="pin icon"
                  className="icon-text-wrapper__icon"
                />
                <p className="element__text">Leisure Explorer</p>
              </span>
              <span className="icon-text-wrapper__element">
                <img
                  src={faceIconBlack}
                  alt="face icon"
                  className="icon-text-wrapper__icon"
                />
                <p className="element__text">Everyday Commuter</p>
              </span>
            </div>
            <div className="text-wrapper__buttons-container">
              <Button buttonStyle="secondary">Compare Services</Button>
              <Button buttonStyle="primary">Join Today</Button>
            </div>
          </div>
        </section>
        <section className="main__offers-card-wrapper">
          {flightsList.map((offer, index) => {
            return (
              <TravelOffersCard
                detailsHandler={() => detailsCardHandler(offer)}
                key={index}
                travelOffers={offer}
                className={``}
                isSelected={offer.id === selectedFlightId}
              />
            );
          })}
        </section>
        <ToolBar buttons={buttonToolbar} clickFilterIcon={clickFilterIcon} />
      </main>
    </div>
  );
};

export default ShowCasePage;
