import destination1 from "../assets/images/offer-destinations-images/destination1.png";
import destination2 from "../assets/images/offer-destinations-images/destination2.png";
import destination3 from "../assets/images/offer-destinations-images/destination3.png";
import destination4 from "../assets/images/offer-destinations-images/destination4.png";
import destination5 from "../assets/images/offer-destinations-images/destination5.png";
import destination6 from "../assets/images/offer-destinations-images/destination6.png";

const travelOffers = [
  {
    destination: "Orlando",
    departure: "Miami",
    points: "69.000",
    image: destination1,
  },
  {
    destination: "West Palm Beach",
    departure: "Orlando",
    points: "58.000",
    image: destination2,
  },
  {
    destination: "Boca Raton",
    departure: "Orlando",
    points: "75.000",
    image: destination3,
  },
  {
    destination: "Fort Lauderdale",
    departure: "Orlando",
    points: "64.000",
    image: destination4,
  },
  {
    destination: "Aventura",
    departure: "Orlando",
    points: "55.000",
    image: destination5,
  },
  {
    destination: "Orlando",
    departure: "Miami",
    points: "55.000",
    image: destination6,
  },
  {
    destination: "Orlando",
    departure: "Miami",
    points: "69.000",
    image: destination1,
  },
  {
    destination: "West Palm Beach",
    departure: "Orlando",
    points: "58.000",
    image: destination2,
  },
  {
    destination: "Boca Raton",
    departure: "Orlando",
    points: "75.000",
    image: destination3,
  },
  {
    destination: "Fort Lauderdale",
    departure: "Orlando",
    points: "64.000",
    image: destination4,
  },
  {
    destination: "Aventura",
    departure: "Orlando",
    points: "55.000",
    image: destination5,
  },
  {
    destination: "Orlando",
    departure: "Miami",
    points: "55.000",
    image: destination6,
  },
];

export default travelOffers;
