import React from "react";
import "./styles.scss";
import { useIconContext } from "contexts/useIconNav";
import { useDeskNavigation } from "contexts/useDeskNavigation";
import Button from "components/Buttons/Button";
import airplane from "./../../assets/icons/airPlanes/airplane-white.svg";
import { useWindowWidthSize } from "contexts/useWindowWidthSize";

interface ToolBarProps {
  className?: string;
  buttons: ButtonsToolbar[];
  clickFilterIcon: () => void;
}

export type ButtonsToolbar = {
  text?: string;
  icon: string;
  action: (a: any) => void;
  className: string;
};

const ToolBar: React.FC<ToolBarProps> = (props) => {
  const { buttons, className, clickFilterIcon } = props;
  const { windowSize } = useWindowWidthSize();

  const middleIndex = Math.floor(buttons.length / 2);

  const toolbarClassNames = [className].join(" ").trim();

  return (
    <footer id="toolbar-component" className={className}>
      <div className="toolbar-component__buttons-wrapper">
        {buttons.map((btn, index) => (
          <React.Fragment key={index}>
            {index === middleIndex && windowSize > 768 ? (
              <div className="toolbar-component__divider">
                <Button buttonStyle="primary" onClick={clickFilterIcon}>
                  <img src={airplane} alt="airplane" />
                  Find Deals
                </Button>
              </div>
            ) : (
              false
            )}
            <button onClick={btn.action} className="buttons-wrapper__button">
              <span
                className={`icon ${btn.icon} ${btn.className} icon-md`}
              ></span>
              {btn.text ? <p className="button__text">{btn.text}</p> : false}
            </button>
          </React.Fragment>
        ))}
      </div>
    </footer>
  );
};

export default ToolBar;
